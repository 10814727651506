import { TableField, TableFieldFilter } from '@/model/tableField';
import { AdminClient } from './AdminClient.config';

const tableFieldsBaseUrl = '/table-fields';

export const queryTableFieldsApi = (filter: TableFieldFilter) =>
  AdminClient.get<TableField[]>(tableFieldsBaseUrl, { params: filter }).then(
    (response) => response.data || []
  );

export const queryTableFieldByIdApi = (tableFielddId: number) =>
  AdminClient.get<TableField | null>(
    `${tableFieldsBaseUrl}/${tableFielddId}`
  ).then((response) => response.data || null);

export const createTableFieldApi = (tableField: TableField) =>
  AdminClient.post<TableField>(tableFieldsBaseUrl, tableField).then(
    (response) => response.data
  );

export const updateTableFieldApi = (tableField: TableField) =>
  AdminClient.put<TableField>(
    `${tableFieldsBaseUrl}/${tableField.id}`,
    tableField
  ).then((response) => response.data);

export const deleteTableFieldApi = (tableFieldId: number) =>
  AdminClient.delete(`${tableFieldsBaseUrl}/${tableFieldId}`).then(() => {});
