import { Field } from '@/model/field';
import { AdminClient } from './AdminClient.config';

const formsBaseUrl = '/forms';
const fieldsBaseUrl = 'fields/';

export const createFormFieldApi = (field: Field) =>
  AdminClient.post<Field>(
    `${formsBaseUrl}/${field.formId}/${fieldsBaseUrl}`,
    field
  ).then((response) => response.data);
