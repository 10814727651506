import { FormsContext } from '@/context/Forms';
import { FormProvider } from '@/context/Forms/FormContext';
import { BaseComponent } from '@/model/BaseComponent';
import { FC, useContext } from 'react';
import { BiPlus } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { FormComponent } from './components/Form';

export const SchemaComponent: FC<BaseComponent> = () => {
  const { forms } = useContext(FormsContext);

  return (
    <section>
      <p className='h1 text-primary fw-bold'>Estructura</p>
      <hr className='panel-hr' />
      <article className='ps-3 scrollable-panel'>
        <header className='d-flex align-items-center'>
          <p className='h1 text-primary fw-bold d-inline-block'>
            Crear Plantilla
          </p>

          <Link to='/' className='ms-3'>
            <button
              className='btn btn-primary btn-circle'
              title='Crear plantilla'
            >
              <BiPlus size={24} className='crud-btn' />
            </button>
          </Link>
        </header>

        <ul className='ls-none'>
          {forms?.map?.((form) => (
            <li key={form.id}>
              <FormProvider form={form}>
                <FormComponent />
              </FormProvider>
            </li>
          ))}
        </ul>
      </article>
    </section>
  );
};
