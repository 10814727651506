import { Category } from '@/model/category';
import {
  CategoryCreatedAction,
  CategoryDeletedAction,
  CategoryInitializeAction,
  CategoryStateActionType,
  CategoryUpdatedAction,
  ICategoryState,
} from './model';

export const createCategoryInitializeAction = (
  categories: Category[]
): CategoryInitializeAction => ({
  type: CategoryStateActionType.INITIALIZE,
  payload: categories,
});

export const categoryInitializeAction = (
  state: ICategoryState,
  action: CategoryInitializeAction
): ICategoryState => ({
  ...state,
  categories: action.payload.slice(),
});

export const createCategoryCreatedAction = (
  category: Category
): CategoryCreatedAction => ({
  type: CategoryStateActionType.CREATED,
  payload: category,
});

export const categoryCreatedAction = (
  state: ICategoryState,
  action: CategoryCreatedAction
): ICategoryState => ({
  ...state,
  categories: state.categories.concat(action.payload),
});

export const createCategoryUpdatedAction = (
  category: Category
): CategoryUpdatedAction => ({
  type: CategoryStateActionType.UPDATED,
  payload: category,
});

export const categoryUpdatedAction = (
  state: ICategoryState,
  { payload }: CategoryUpdatedAction
): ICategoryState => ({
  ...state,
  categories: state.categories.map((category) =>
    category.id === payload.id ? payload : category
  ),
});

export const createCategoryDeletedAction = (
  id: number
): CategoryDeletedAction => ({
  type: CategoryStateActionType.DELETED,
  payload: id,
});

export const categoryDeletedAction = (
  state: ICategoryState,
  action: CategoryDeletedAction
): ICategoryState => ({
  ...state,
  categories: state.categories.filter(
    (category) => category.id !== action.payload
  ),
});
