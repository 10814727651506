import { Selector } from '@/model/selector';
import { AdminClient } from './AdminClient.config';

const selectorsBaseUrl = '/selectors';

export const createSelectorApi = (selector: Selector) =>
  AdminClient.post<Selector>(selectorsBaseUrl, selector).then(
    (response) => response.data
  );

export const updateSelectorApi = (selector: Selector) =>
  AdminClient.put<Selector>(
    `${selectorsBaseUrl}/${selector.id}`,
    selector
  ).then((response) => response.data);

export const querySelectorByIdApi = (selectorId: number) =>
  AdminClient.get<Selector | null>(`${selectorsBaseUrl}/${selectorId}`).then(
    (response) => response.data || null
  );

export const querySelectorsApi = () =>
  AdminClient.get<Selector[]>(selectorsBaseUrl).then(
    (response) => response.data || []
  );

export const deleteSelectorApi = (selectorId: number) =>
  AdminClient.delete(`${selectorsBaseUrl}/${selectorId}`).then(() => {});
