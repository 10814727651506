import { Field } from '@/model/field';
import {
  FieldCreatedAction,
  FieldDeletedAction,
  FieldInitializeAction,
  FieldStateActionType,
  FieldUpdatedAction,
  IFieldState,
} from './model';

export const createFieldInitializeAction = (
  fields: Field[]
): FieldInitializeAction => ({
  type: FieldStateActionType.INITIALIZE,
  payload: fields,
});

export const fieldInitializeAction = (
  state: IFieldState,
  action: FieldInitializeAction
): IFieldState => ({
  ...state,
  fields: action.payload.slice(),
});

export const createFieldCreatedAction = (field: Field): FieldCreatedAction => ({
  type: FieldStateActionType.CREATED,
  payload: field,
});

export const fieldCreatedAction = (
  state: IFieldState,
  action: FieldCreatedAction
): IFieldState => ({
  ...state,
  fields: state.fields.concat(action.payload),
});

export const createFieldUpdatedAction = (field: Field): FieldUpdatedAction => ({
  type: FieldStateActionType.UPDATED,
  payload: field,
});

export const fieldUpdatedAction = (
  state: IFieldState,
  { payload }: FieldUpdatedAction
): IFieldState => ({
  ...state,
  fields: state.fields.map((field) =>
    field.id === payload.id ? payload : field
  ),
});

export const createFieldDeletedAction = (id: number): FieldDeletedAction => ({
  type: FieldStateActionType.DELETED,
  payload: id,
});

export const fieldDeletedAction = (
  state: IFieldState,
  action: FieldDeletedAction
): IFieldState => ({
  ...state,
  fields: state.fields.filter((field) => field.id !== action.payload),
});
