import { TableFieldsContext } from '@/context/TableFields';
import { FC, useContext, useState } from 'react';
import { AiFillCaretRight } from 'react-icons/ai';
import { BiPlus } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { TableFieldComponent } from './TableField';

export const TableFields: FC = () => {
  const [visible, setVisible] = useState(false);
  const { field, tableFields, queryTableFields } =
    useContext(TableFieldsContext);

  const toggleVisible = () => {
    if (!visible) {
      queryTableFields();
    }

    setVisible((prev) => !prev);
  };

  return (
    <div>
      <div className='d-flex align-items-center'>
        <span onClick={toggleVisible} className='d-flex align-items-center'>
          <AiFillCaretRight
            className={`caret ${visible ? 'caret--active' : ''} text-primary`}
          />
        </span>

        <span className='h4 mb-0 text-primary d-flex align-items-center gap-2'>
          Campos
        </span>

        <Link to={`/tablas/${field.id}/campos`} className='ms-2'>
          <button className='btn btn-primary btn-circle' title='Crear campo'>
            <BiPlus size={24} className='crud-btn' />
          </button>
        </Link>
      </div>

      {visible && (
        <ul className='ls-none'>
          {tableFields.map((tableField) => (
            <li key={tableField.id}>
              <TableFieldComponent tableField={tableField} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
