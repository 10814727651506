import { createFieldEnablementApi } from '@/api/fieldEnablement.api';
import { queryFieldsApi } from '@/api/fields.api';
import { queryFormCategoriesApi } from '@/api/formCategories.api';
import { EnablementRequest } from '@/model/enablement';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

const defaultValue: EnablementRequest = {
  id: undefined,
  elementType: undefined,
  enable: undefined,
};

export interface AutocompleteOption {
  label: string;
  id: number;
}

export interface AddEnablementComponentProps {
  isOpen: boolean;
  onClose: () => void;
  fieldId: number;
  formId: number;
  categoryId: number;
}

export const AddEnablementComponent: FC<AddEnablementComponentProps> = ({
  isOpen,
  onClose,
  fieldId,
  formId,
  categoryId,
}) => {
  const [enablement, setEnablement] = useState<EnablementRequest>(defaultValue);
  const [options, setOptions] = useState<AutocompleteOption[]>([]);

  const setInput = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setEnablement((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetForm = () => setEnablement(defaultValue);

  const onFormSubmit = () => {
    createFieldEnablementApi(fieldId, enablement)
      .then(() => {
        toast('Registro creado con éxito', { type: 'success' });
        setOptions([]);
        resetForm();
        onClose();

        Events.publish(`${formId}_${fieldId}_${EventTypes.FIELD_ENABLEMENT}`, {
          type: 'create',
          name: options.find((option) => option.id === enablement.id)?.label!,
          enablementRequest: enablement,
        });
      })
      .catch(() => {
        toast('Error al crear el registro', { type: 'error' });
      });
  };

  const selectedElement: AutocompleteOption | null = useMemo(
    () => options.find((option) => option.id === enablement.id) || null,
    [enablement, options]
  );

  useEffect(() => {
    if (!enablement.elementType) {
      setOptions([]);
      return;
    }

    let dropResponse = false;

    if (enablement.elementType === 'field') {
      queryFieldsApi({ formId }).then((fields) => {
        if (dropResponse) return;

        setOptions(
          fields
            .filter((field) => field.id !== fieldId)
            .map((field) => ({
              label: field.name,
              id: field.id!,
            }))
        );
      });
    } else {
      queryFormCategoriesApi({ formId, onlyParent: false }).then(
        (categories) => {
          if (dropResponse) return;

          setOptions(
            categories
              .filter((category) => category.id !== categoryId)
              .map((category) => ({
                label: category.name,
                id: category.id!,
              }))
          );
        }
      );
    }

    return () => {
      dropResponse = true;
    };
  }, [enablement.elementType, formId, fieldId, categoryId]);

  return (
    <form>
      <Dialog fullWidth maxWidth='sm' open={isOpen} onClose={onClose}>
        <DialogContent className='d-grid gap-3'>
          <div>
            <label
              className='form-label'
              htmlFor='elementType'
              placeholder='Tipo de elemento a habilitar/deshabilitar'
            >
              Tipo de elemento
            </label>

            <select
              className='form-select'
              name='elementType'
              onChange={setInput}
              value={enablement.elementType}
              required
            >
              <option value=''>Seleccione una opción</option>

              <option value='field'>Campo</option>

              <option value='category'>Categoría</option>
            </select>
          </div>

          <div>
            <label
              className='form-label'
              htmlFor='enable'
              placeholder='Habilitar/Deshabilitar'
            >
              Habilitar/Deshabilitar
            </label>

            <select
              className='form-select'
              name='enable'
              onChange={setInput}
              value={enablement.enable as any}
              required
            >
              <option value=''>Seleccione una opción</option>

              <option value={true as any}>Habilitar</option>

              <option value={false as any}>Deshabilitar</option>
            </select>
          </div>

          <div>
            <label className='form-label' htmlFor='id' placeholder='id'>
              Categoría/Campo
            </label>

            <Autocomplete
              id='id'
              options={options}
              value={selectedElement}
              onChange={(_, value) =>
                setEnablement((prev) => ({ ...prev, id: value?.id }))
              }
              renderInput={(params) => (
                <TextField {...params} label='Elemento' />
              )}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <div className='col-12'>
            <div className='row'>
              <div className='d-grid col-4'>
                <button
                  className='btn btn-secondary'
                  type='button'
                  onClick={resetForm}
                >
                  Restablecer
                </button>
              </div>

              <div className='d-grid col-4'>
                <button
                  className='btn btn-primary w-100'
                  type='button'
                  onClick={onFormSubmit}
                >
                  Guardar
                </button>
              </div>

              <div className='d-grid col-4'>
                <button
                  className='btn btn-danger w-100'
                  type='button'
                  onClick={onClose}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </form>
  );
};
