import { Field, FieldsFilter } from '@/model/field';
import { AdminClient } from './AdminClient.config';

const fieldsBaseUrl = '/fields';

export const queryFieldsApi = (filter: FieldsFilter) => {
  return AdminClient.get<Field[]>(fieldsBaseUrl, { params: filter }).then(
    (response) => response.data || []
  );
};

export const updateFieldApi = (field: Field) =>
  AdminClient.put<Field>(`${fieldsBaseUrl}/${field.id}`, field).then(
    (response) => response.data
  );

export const queryFieldByIdApi = (fieldId: number) =>
  AdminClient.get<Field | null>(`${fieldsBaseUrl}/${fieldId}`).then(
    (response) => response.data || null
  );

export const deleteFieldApi = (fieldId: number) =>
  AdminClient.delete(`${fieldsBaseUrl}/${fieldId}`).then(() => {});
