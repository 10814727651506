import { Validation } from '@/model/validation';
import { AdminClient } from './AdminClient.config';

const validationsBaseUrl = '/validations';

export const createValidationApi = (validation: Validation) =>
  AdminClient.post<Validation>(validationsBaseUrl, validation).then(
    (response) => response.data
  );

export const updateValidationApi = (validation: Validation) =>
  AdminClient.put<Validation>(
    `${validationsBaseUrl}/${validation.id}`,
    validation
  ).then((response) => response.data);

export const queryValidationsApi = () =>
  AdminClient.get<Validation[]>(validationsBaseUrl).then(
    (response) => response.data || []
  );

export const queryValidationByIdApi = (validationId: number) =>
  AdminClient.get<Validation | null>(
    `${validationsBaseUrl}/${validationId}`
  ).then((response) => response.data || null);

export const deleteValidationApi = (validationId: number) =>
  AdminClient.delete(`${validationsBaseUrl}/${validationId}`).then(() => {});
