import { deleteFieldApi } from '@/api/fields.api';
import { ConfirmDialogComponent } from '@/components/ConfirmDialog';
import { FormsContext } from '@/context/Forms';
import { TableFieldProvider } from '@/context/TableFields';
import { Field } from '@/model/field';
import { FieldType } from '@/model/fieldType';
import { createFieldDeletedAction } from '@/store/fields/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC, useContext, useState } from 'react';
import { AiFillCaretRight } from 'react-icons/ai';
import { BiMinus, BiSolidPencil } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FieldEnablementComponent } from './Enablement';
import { FieldIconAdapterComponent } from './FieldIconAdapter';
import { TableFields } from './TableFields';

export interface FieldAdapterComponentProps {
  field?: Field;
}

export const FieldAdapterComponent: FC<FieldAdapterComponentProps> = ({
  field,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const { validations, selectors } = useContext(FormsContext);

  const toggleVisible = () => {
    setVisible((prev) => !prev);
  };

  const onDeleteField = () => {
    deleteFieldApi(field?.id!).then(() => {
      toast('Campo eliminado.', { type: 'error' });
      Events.publish(
        `${field?.formId}_${field?.categoryId}_${EventTypes.FIELDS}`,
        createFieldDeletedAction(field?.id!)
      );
    });
  };

  if (!field) {
    return null;
  }

  switch (field.type) {
    default:
      return (
        <div>
          <div className='d-flex align-items-center'>
            <span onClick={toggleVisible} className='d-flex align-items-center'>
              <AiFillCaretRight
                className={`caret ${
                  visible ? 'caret--active' : ''
                } text-primary`}
              />
            </span>

            <span className='h4 mb-0 text-primary d-flex align-items-center gap-2'>
              <FieldIconAdapterComponent fieldType={field.type} />
              {field.name}
            </span>

            <Link
              to={`/plantillas/${field.formId}/categorias/${field.categoryId}/campos/${field.id}`}
              className='ms-2'
            >
              <button
                className='btn btn-dark btn-circle'
                title='Actualizar plantilla'
              >
                <BiSolidPencil size={16} className='crud-btn' />
              </button>
            </Link>

            <button
              className='btn btn-danger btn-circle ms-2'
              title='Eliminar campo'
              onClick={() => setIsOpen(true)}
            >
              <BiMinus size={24} className='crud-btn' />
            </button>
          </div>

          <ul className={`ls-none ${visible ? '' : 'visually-hidden'}`}>
            {field.validationId && (
              <li className='h5'>
                Validación:{' '}
                {
                  validations.find(
                    (validation) => validation.id === field.validationId
                  )?.name
                }
              </li>
            )}

            {field.selectorId && (
              <li className='h5'>
                Selector:{' '}
                {
                  selectors.find((selector) => selector.id === field.selectorId)
                    ?.name
                }
              </li>
            )}

            {field.type === FieldType.BOOLEAN && (
              <FieldEnablementComponent field={field} />
            )}

            {field.type === FieldType.TABLE && (
              <li>
                <TableFieldProvider field={field}>
                  <TableFields />
                </TableFieldProvider>
              </li>
            )}
          </ul>

          <ConfirmDialogComponent
            isOpen={isOpen}
            title='Eliminar campo'
            message='¿Está seguro que desea eliminar este campo?'
            onCancel={() => setIsOpen(false)}
            onConfirm={onDeleteField}
          />
        </div>
      );
  }
};
