import { querySelectorByIdApi, updateSelectorApi } from '@/api/selectors.api';
import { SelectorsFormComponent } from '@/components/SelectorsForm';
import { Selector } from '@/model/selector';
import { createSelectorUpdatedAction } from '@/store/selectors/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const UpdateSelectorPage: FC = () => {
  const { selectorId } = useParams<{ selectorId: string }>();
  const [selector, setSelector] = useState<Selector | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let dropResult = false;
    setIsLoading(true);

    querySelectorByIdApi(+selectorId!)
      .then((foundSelector) => {
        if (dropResult) {
          return;
        }

        setSelector(foundSelector);
      })
      .finally(() => {
        if (dropResult) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      dropResult = true;
    };
  }, [selectorId]);

  const onSuccess = (selector: Selector) => {
    toast('Selector Actualizado.', {
      type: 'info',
    });

    Events.publish(EventTypes.SELECTORS, createSelectorUpdatedAction(selector));
  };

  const onSubmit = (selector: Selector) => {
    return updateSelectorApi({ ...selector, id: +selectorId! }).then(
      (updatedSelector) => {
        onSuccess(updatedSelector);
        return updatedSelector;
      }
    );
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <section className='container-fluid'>
      <p className='h1 text-primary fw-bold'>Actualizar selector</p>
      <hr className='panel-hr' />

      <SelectorsFormComponent
        defaultValue={selector!}
        onSubmit={onSubmit}
        resetOnSubmit={false}
      />
    </section>
  );
};
