import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FC } from 'react';

export interface ConfirmDialogComponentProps {
  isOpen: boolean;
  title: string;
  message?: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmDialogComponent: FC<ConfirmDialogComponentProps> = ({
  isOpen,
  title,
  message,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>

      {message && (
        <DialogContent>
          <p className='mb-0'>{message}</p>
        </DialogContent>
      )}

      <DialogActions>
        <section className='col-12'>
          <div className='row'>
            <div className='d-grid col-6'>
              <button
                className='btn btn-danger'
                type='button'
                onClick={onConfirm}
              >
                Aceptar
              </button>
            </div>

            <div className='d-grid col-6'>
              <button
                className='btn btn-primary'
                type='button'
                onClick={onCancel}
              >
                Cancelar
              </button>
            </div>
          </div>
        </section>
      </DialogActions>
    </Dialog>
  );
};
