import { SelectorDetail } from '@/model/selectorDetail';
import { ChangeEvent, FC, useState } from 'react';

const formDefaultValue: SelectorDetail = {
  label: '',
  value: '',
  order: 1,
};

export interface SelectorDetailsFormComponentProps {
  defaultValue?: SelectorDetail;
  onSubmit: (selectorDetail: SelectorDetail) => Promise<SelectorDetail>;
  resetOnSubmit?: boolean;
}

export const SelectorDetailsFormComponent: FC<
  SelectorDetailsFormComponentProps
> = ({ defaultValue, onSubmit, resetOnSubmit = true }) => {
  const [selectorDetail, setSelectorDetail] = useState(
    defaultValue || formDefaultValue
  );

  const resetForm = () => setSelectorDetail(formDefaultValue);

  const onFormSubmit = () => {
    onSubmit(selectorDetail).then(() => {
      if (resetOnSubmit) {
        resetForm();
      }
    });
  };

  const setInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setSelectorDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <form className='d-grid gap-3'>
      <div>
        <label
          className='form-label'
          htmlFor='label'
          placeholder='Etiqueta de la opción'
        >
          Etiqueta
        </label>

        <input
          className='form-control'
          value={selectorDetail.label}
          type='text'
          name='label'
          onChange={setInput}
          required
        />
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='value'
          placeholder='Valor de la opción'
        >
          Valor
        </label>

        <input
          className='form-control'
          value={selectorDetail.value}
          type='text'
          name='value'
          onChange={setInput}
          required
        />
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='order'
          placeholder='Orden de la opción'
        >
          Orden
        </label>

        <input
          className='form-control'
          value={selectorDetail.order}
          min={1}
          step={1}
          type='number'
          name='order'
          onChange={setInput}
          required
        />
      </div>

      <section className='col-12'>
        <div className='row'>
          <div className='d-grid col-6'>
            <button
              className='btn btn-secondary'
              type='button'
              onClick={resetForm}
            >
              Restablecer
            </button>
          </div>

          <div className='d-grid col-6'>
            <button
              className='btn btn-primary'
              type='button'
              onClick={onFormSubmit}
            >
              Guardar
            </button>
          </div>
        </div>
      </section>
    </form>
  );
};
