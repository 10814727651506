const EventTypes = Object.freeze({
  FORMS: 'FORMS',
  CATEGORIES: 'CATEGORIES',
  FIELDS: 'FIELDS',
  TABLE_FIELDS: 'TABLE_FIELDS',
  VALIDATIONS: 'VALIDATIONS',
  SELECTORS: 'SELECTORS',
  FIELD_ENABLEMENT: 'FIELD_ENABLEMENT',
  TABLE_FIELD_ENABLEMENT: 'TABLE_FIELD_ENABLEMENT',
});

export default EventTypes;
