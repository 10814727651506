import { MainPage } from '@/pages/MainPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { NavBar } from './components/NavBar';
import { FormsProvider } from './context/Forms';
import { CreateCategoryPage } from './pages/CreateCategoryPage';
import { CreateFieldPage } from './pages/CreateFieldPage';
import { CreateFormPage } from './pages/CreateFormPage';
import { CreateSelectorDetailPage } from './pages/CreateSelectorDetailPage';
import { CreateSelectorPage } from './pages/CreateSelectorPage';
import { CreateTableFieldPage } from './pages/CreateTableFieldPage';
import { CreateValidationPage } from './pages/CreateValidationPage';
import { ListSelectorDetailsPage } from './pages/ListSelectorDetailsPage';
import { ListselectorsPage } from './pages/ListSelectorsPage';
import { ListValidationsPage } from './pages/ListValidationsPage';
import { UpdateCategoryPage } from './pages/UpdateCategoryPage';
import { UpdateFieldPage } from './pages/UpdateFieldPage';
import { UpdateFormPage } from './pages/UpdateFormPage';
import { UpdateSelectorDetailPage } from './pages/UpdateSelectorDetailPage';
import { UpdateSelectorPage } from './pages/UpdateSelectorPage';
import { UpdateTableFieldPage } from './pages/UpdateTableFieldPage';
import { UpdateValidationPage } from './pages/UpdateValidationPage';

function App() {
  return (
    <FormsProvider>
      <BrowserRouter>
        <NavBar />

        <MainPage>
          <Routes>
            <Route path='/' element={<CreateFormPage />}></Route>

            <Route path='/plantillas/:formId'>
              <Route index element={<UpdateFormPage />} />

              <Route path='categorias'>
                <Route index element={<CreateCategoryPage />} />

                <Route path=':categoryId'>
                  <Route index element={<UpdateCategoryPage />} />

                  <Route path='campos'>
                    <Route index element={<CreateFieldPage />} />

                    <Route path=':fieldId' element={<UpdateFieldPage />} />
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route path='/tablas/:fieldId/campos'>
              <Route index element={<CreateTableFieldPage />} />

              <Route path=':tableFieldId' element={<UpdateTableFieldPage />} />
            </Route>

            <Route path='/validaciones'>
              <Route index element={<ListValidationsPage />} />

              <Route path='crear' element={<CreateValidationPage />} />
              <Route path=':validationId' element={<UpdateValidationPage />} />
            </Route>

            <Route path='/selectores'>
              <Route index element={<ListselectorsPage />} />

              <Route path='crear' element={<CreateSelectorPage />} />
              <Route path=':selectorId'>
                <Route index element={<UpdateSelectorPage />} />

                <Route path='opciones'>
                  <Route index element={<ListSelectorDetailsPage />} />

                  <Route path='crear' element={<CreateSelectorDetailPage />} />

                  <Route
                    path=':detailId'
                    element={<UpdateSelectorDetailPage />}
                  />
                </Route>
              </Route>
            </Route>
          </Routes>
        </MainPage>
      </BrowserRouter>
    </FormsProvider>
  );
}

export default App;
