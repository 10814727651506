import { Field } from '@/model/field';

export interface IFieldState {
  fields: Field[];
}

export enum FieldStateActionType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  INITIALIZE = 'INITIALIZE',
}

export interface FieldStateActionBase {
  type: FieldStateActionType;
}

export interface FieldInitializeAction extends FieldStateActionBase {
  type: FieldStateActionType.INITIALIZE;
  payload: Field[];
}

export interface FieldCreatedAction extends FieldStateActionBase {
  type: FieldStateActionType.CREATED;
  payload: Field;
}

export interface FieldUpdatedAction extends FieldStateActionBase {
  type: FieldStateActionType.UPDATED;
  payload: Field;
}

export interface FieldDeletedAction extends FieldStateActionBase {
  type: FieldStateActionType.DELETED;
  payload: number;
}

export type FieldStateAction =
  | FieldInitializeAction
  | FieldCreatedAction
  | FieldUpdatedAction
  | FieldDeletedAction;
