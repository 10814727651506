import { queryFieldEnablementApi } from '@/api/fieldEnablement.api';
import { EnableItem, Enablement, EnablementRequest } from '@/model/enablement';
import { Field } from '@/model/field';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC, useCallback, useEffect, useState } from 'react';
import { AiFillCaretRight } from 'react-icons/ai';
import { BiPlus } from 'react-icons/bi';
import { AddEnablementComponent } from './AddEnablement';
import { EnablementeItemsComponent } from './EnablementItems';

export interface FieldEnablementComponentProps {
  field: Field;
}

export interface EnablementEvent {
  type: 'delete' | 'create';
  name: string;
  enablementRequest: EnablementRequest;
}

export const FieldEnablementComponent: FC<FieldEnablementComponentProps> = ({
  field,
}) => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [loadEnablement, setLoadEnablement] = useState(true);
  const [visible, setVisible] = useState(false);
  const [enablement, setEnablement] = useState<Enablement>();

  const queryEnablement = () => {
    if (
      (!field.enableCategories &&
        !field.disableCategories &&
        !field.enableFields &&
        !field.disableFields) ||
      !loadEnablement
    ) {
      setLoadEnablement(false);
      return;
    }

    setLoadEnablement(false);
    queryFieldEnablementApi(field.id!)
      .then((foundEnablement) => setEnablement(foundEnablement))
      .catch(() => setLoadEnablement(true));
  };

  const toggleVisible = () => {
    if (!visible) {
      queryEnablement();
    }

    setVisible((prev) => !prev);
  };

  const onEnablementEvent = useCallback(
    ({ detail }: CustomEvent<EnablementEvent>) => {
      const { type, enablementRequest, name } = detail;
      const { elementType, id, enable } = enablementRequest;

      let key = '';

      key += enable ? 'enable' : 'disable';
      key += elementType === 'field' ? 'Fields' : 'Categories';

      const data: EnableItem[] = enablement?.[key as keyof Enablement] || [];
      let newData: EnableItem[];

      if (type === 'create') {
        newData = [...data, { id: id!, name }];
      } else if (type === 'delete') {
        newData = data.filter((item) => item.id !== id);
      }

      setEnablement((prevState) => {
        if (!prevState) {
          return {
            enableFields: [],
            enableCategories: [],
            disableCategories: [],
            disableFields: [],
            [key]: newData,
          };
        }

        return { ...prevState, [key]: newData };
      });
    },
    [enablement]
  );

  useEffect(() => {
    const eventName = `${field.formId}_${field.id}_${EventTypes.FIELD_ENABLEMENT}`;
    Events.subscribe(eventName, onEnablementEvent);

    return () => {
      Events.unsubscribe(eventName, onEnablementEvent);
    };
  }, [field, onEnablementEvent]);

  return (
    <div>
      <div className='d-flex align-items-center'>
        <span onClick={toggleVisible} className='d-flex align-items-center'>
          <AiFillCaretRight
            className={`caret ${visible ? 'caret--active' : ''} text-primary`}
          />
        </span>

        <span className='h5 mb-0 text-primary d-flex align-items-center gap-2'>
          Habilitación de campos y categorías
        </span>

        <button
          className='btn btn-primary btn-circle ms-2'
          title='Crear subcategoría'
        >
          <BiPlus
            size={24}
            className='crud-btn'
            onClick={() => setIsAddOpen(true)}
          />
        </button>
      </div>

      <ul className={`ls-none ${visible ? '' : 'visually-hidden'}`}>
        <li>
          <EnablementeItemsComponent
            title='Habilitar campos'
            enablementItems={enablement?.enableFields || []}
            fieldId={field.id!}
            formId={field.formId!}
            elementType='field'
            enable
          />
        </li>
        <li>
          <EnablementeItemsComponent
            title='Deshabilitar campos'
            enablementItems={enablement?.disableFields || []}
            fieldId={field.id!}
            formId={field.formId!}
            elementType='field'
            enable={false}
          />
        </li>
        <li>
          <EnablementeItemsComponent
            title='Habilitar Categorías'
            enablementItems={enablement?.enableCategories || []}
            fieldId={field.id!}
            formId={field.formId!}
            elementType='category'
            enable
          />
        </li>
        <li>
          <EnablementeItemsComponent
            title='Deshabilitar Categorías'
            enablementItems={enablement?.disableCategories || []}
            fieldId={field.id!}
            formId={field.formId!}
            elementType='category'
            enable={false}
          />
        </li>
      </ul>

      <AddEnablementComponent
        isOpen={isAddOpen}
        onClose={() => setIsAddOpen(false)}
        fieldId={field.id!}
        formId={field.formId!}
        categoryId={field.categoryId!}
      />
    </div>
  );
};
