export const FieldType = {
  CAPTURE: 'capture',
  SELECT: 'select',
  STRING: 'string',
  DATE: 'date',
  HOUR: 'hour',
  LATITUDE: 'latitud',
  LONGITUDE: 'longitud',
  BOOLEAN: 'bool',
  INTEGER: 'integer',
  FLOAT: 'float',
  MONEY: 'money',
  TABLE: 'table',
  MONTHS_MULTIPLE: 'months_multiple',
} as const;

export type FieldType = (typeof FieldType)[keyof typeof FieldType];
