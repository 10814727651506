import { Validation } from '@/model/validation';

export interface IValidationState {
  validations: Validation[];
}

export enum ValidationStateActionType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  INITIALIZE = 'INITIALIZE',
}

export interface ValidationStateActionBase {
  type: ValidationStateActionType;
}

export interface ValidationInitializeAction extends ValidationStateActionBase {
  type: ValidationStateActionType.INITIALIZE;
  payload: Validation[];
}

export interface ValidationCreatedAction extends ValidationStateActionBase {
  type: ValidationStateActionType.CREATED;
  payload: Validation;
}

export interface ValidationUpdatedAction extends ValidationStateActionBase {
  type: ValidationStateActionType.UPDATED;
  payload: Validation;
}

export interface ValidationDeletedAction extends ValidationStateActionBase {
  type: ValidationStateActionType.DELETED;
  payload: number;
}

export type ValidationStateAction =
  | ValidationInitializeAction
  | ValidationCreatedAction
  | ValidationUpdatedAction
  | ValidationDeletedAction;
