import { Selector } from '@/model/selector';
import {
  ISelectorState,
  SelectorCreatedAction,
  SelectorDeletedAction,
  SelectorInitializeAction,
  SelectorStateActionType,
  SelectorUpdatedAction,
} from './model';

export const createSelectorInitializeAction = (
  selectors: Selector[]
): SelectorInitializeAction => ({
  type: SelectorStateActionType.INITIALIZE,
  payload: selectors,
});

export const selectorInitializeAction = (
  state: ISelectorState,
  action: SelectorInitializeAction
): ISelectorState => ({
  ...state,
  selectors: action.payload.slice(),
});

export const createSelectorCreatedAction = (
  selector: Selector
): SelectorCreatedAction => ({
  type: SelectorStateActionType.CREATED,
  payload: selector,
});

export const selectorCreatedAction = (
  state: ISelectorState,
  action: SelectorCreatedAction
): ISelectorState => ({
  ...state,
  selectors: state.selectors.concat(action.payload),
});

export const createSelectorUpdatedAction = (
  selector: Selector
): SelectorUpdatedAction => ({
  type: SelectorStateActionType.UPDATED,
  payload: selector,
});

export const selectorUpdatedAction = (
  state: ISelectorState,
  { payload }: SelectorUpdatedAction
): ISelectorState => ({
  ...state,
  selectors: state.selectors.map((selector) =>
    selector.id === payload.id ? payload : selector
  ),
});

export const createSelectorDeletedAction = (
  id: number
): SelectorDeletedAction => ({
  type: SelectorStateActionType.DELETED,
  payload: id,
});

export const selectorDeletedAction = (
  state: ISelectorState,
  action: SelectorDeletedAction
): ISelectorState => ({
  ...state,
  selectors: state.selectors.filter(
    (selector) => selector.id !== action.payload
  ),
});
