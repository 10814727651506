import { Dispatch } from 'react';
import {
  validationCreatedAction,
  validationDeletedAction,
  validationInitializeAction,
  validationUpdatedAction,
} from './actions';
import {
  IValidationState,
  ValidationStateAction,
  ValidationStateActionType,
} from './model';

const defaultState: IValidationState = { validations: [] };

const reducer = (
  state: IValidationState = defaultState,
  { detail: action }: CustomEvent<ValidationStateAction>
) => {
  switch (action.type) {
    case ValidationStateActionType.INITIALIZE:
      return validationInitializeAction(state, action);
    case ValidationStateActionType.CREATED:
      return validationCreatedAction(state, action);
    case ValidationStateActionType.UPDATED:
      return validationUpdatedAction(state, action);
    case ValidationStateActionType.DELETED:
      return validationDeletedAction(state, action);
    default:
      return { ...state };
  }
};

const eventsHanlder = (
  event: CustomEvent<ValidationStateAction>,
  dispatcher: Dispatch<ValidationStateAction>
) => dispatcher(event.detail);

const ValidationState = Object.freeze({ reducer, defaultState, eventsHanlder });

export default ValidationState;
