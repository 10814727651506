import { Dispatch } from 'react';
import {
  categoryCreatedAction,
  categoryDeletedAction,
  categoryInitializeAction,
  categoryUpdatedAction,
} from './actions';
import {
  CategoryStateAction,
  CategoryStateActionType,
  ICategoryState,
} from './model';

const defaultState: ICategoryState = { categories: [] };

const reducer = (
  state: ICategoryState = defaultState,
  { detail: action }: CustomEvent<CategoryStateAction>
) => {
  switch (action.type) {
    case CategoryStateActionType.INITIALIZE:
      return categoryInitializeAction(state, action);
    case CategoryStateActionType.CREATED:
      return categoryCreatedAction(state, action);
    case CategoryStateActionType.UPDATED:
      return categoryUpdatedAction(state, action);
    case CategoryStateActionType.DELETED:
      return categoryDeletedAction(state, action);
    default:
      return { ...state };
  }
};

const eventsHanlder = (
  event: CustomEvent<CategoryStateAction>,
  dispatcher: Dispatch<CategoryStateAction>
) => dispatcher(event.detail);

const CategoryState = Object.freeze({ reducer, defaultState, eventsHanlder });

export default CategoryState;
