import { Selector } from '@/model/selector';

export interface ISelectorState {
  selectors: Selector[];
}

export enum SelectorStateActionType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  INITIALIZE = 'INITIALIZE',
}

export interface SelectorStateActionBase {
  type: SelectorStateActionType;
}

export interface SelectorInitializeAction extends SelectorStateActionBase {
  type: SelectorStateActionType.INITIALIZE;
  payload: Selector[];
}

export interface SelectorCreatedAction extends SelectorStateActionBase {
  type: SelectorStateActionType.CREATED;
  payload: Selector;
}

export interface SelectorUpdatedAction extends SelectorStateActionBase {
  type: SelectorStateActionType.UPDATED;
  payload: Selector;
}

export interface SelectorDeletedAction extends SelectorStateActionBase {
  type: SelectorStateActionType.DELETED;
  payload: number;
}

export type SelectorStateAction =
  | SelectorInitializeAction
  | SelectorCreatedAction
  | SelectorUpdatedAction
  | SelectorDeletedAction;
