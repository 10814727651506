import { Validation } from '@/model/validation';
import {
  IValidationState,
  ValidationCreatedAction,
  ValidationDeletedAction,
  ValidationInitializeAction,
  ValidationStateActionType,
  ValidationUpdatedAction,
} from './model';

export const createValidationInitializeAction = (
  validations: Validation[]
): ValidationInitializeAction => ({
  type: ValidationStateActionType.INITIALIZE,
  payload: validations,
});

export const validationInitializeAction = (
  state: IValidationState,
  action: ValidationInitializeAction
): IValidationState => ({
  ...state,
  validations: action.payload.slice(),
});

export const createValidationCreatedAction = (
  validation: Validation
): ValidationCreatedAction => ({
  type: ValidationStateActionType.CREATED,
  payload: validation,
});

export const validationCreatedAction = (
  state: IValidationState,
  action: ValidationCreatedAction
): IValidationState => ({
  ...state,
  validations: state.validations.concat(action.payload),
});

export const createValidationUpdatedAction = (
  validation: Validation
): ValidationUpdatedAction => ({
  type: ValidationStateActionType.UPDATED,
  payload: validation,
});

export const validationUpdatedAction = (
  state: IValidationState,
  { payload }: ValidationUpdatedAction
): IValidationState => ({
  ...state,
  validations: state.validations.map((validation) =>
    validation.id === payload.id ? payload : validation
  ),
});

export const createValidationDeletedAction = (
  id: number
): ValidationDeletedAction => ({
  type: ValidationStateActionType.DELETED,
  payload: id,
});

export const validationDeletedAction = (
  state: IValidationState,
  action: ValidationDeletedAction
): IValidationState => ({
  ...state,
  validations: state.validations.filter(
    (validation) => validation.id !== action.payload
  ),
});
