import { queryFieldByIdApi, updateFieldApi } from '@/api/fields.api';
import { FieldsFormComponent } from '@/components/FieldsForm';
import { Field } from '@/model/field';
import { createFieldUpdatedAction } from '@/store/fields/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const UpdateFieldPage: FC = () => {
  const { fieldId } = useParams<{ fieldId: string }>();
  const [field, setField] = useState<Field | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let dropResult = false;
    setIsLoading(true);

    queryFieldByIdApi(+fieldId!)
      .then((foundField) => {
        if (dropResult) {
          return;
        }

        setField(foundField);
      })
      .finally(() => {
        if (dropResult) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      dropResult = true;
    };
  }, [fieldId]);

  const onSuccess = (field: Field) => {
    toast('Campo Actualizado.', {
      type: 'info',
    });

    Events.publish(
      `${field.formId}_${field.categoryId}_${EventTypes.FIELDS}`,
      createFieldUpdatedAction(field)
    );
  };

  const onSubmit = (field: Field) => {
    return updateFieldApi({ ...field, id: +fieldId! }).then((updatedField) => {
      onSuccess(updatedField);
      return updatedField;
    });
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <section className='container-fluid'>
      <p className='h1 text-primary fw-bold'>Actualizar campo</p>
      <hr className='panel-hr' />

      <FieldsFormComponent
        defaultValue={field!}
        onSubmit={onSubmit}
        resetOnSubmit={false}
      />
    </section>
  );
};
