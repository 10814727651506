import { createFormApi } from '@/api/forms.api';
import { FormsFormComponent } from '@/components/FormsForm';
import { Form } from '@/model/form';
import { createFormCreatedAction } from '@/store/forms/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC } from 'react';
import { toast } from 'react-toastify';

export const CreateFormPage: FC = () => {
  const onSuccess = (form: Form) => {
    toast('Plantilla creada.', {
      type: 'success',
    });

    Events.publish(EventTypes.FORMS, createFormCreatedAction(form));
  };

  const onSubmit = (form: Form) => {
    return createFormApi(form).then((newForm) => {
      onSuccess(newForm);
      return newForm;
    });
  };

  return (
    <section className='container-fluid'>
      <p className='h1 text-primary fw-bold'>Nueva plantilla</p>
      <hr className='panel-hr' />

      <FormsFormComponent onSubmit={onSubmit} />
    </section>
  );
};
