import { TableField } from '@/model/tableField';

export interface ITableFieldState {
  tableFields: TableField[];
}

export enum TableFieldStateActionType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  INITIALIZE = 'INITIALIZE',
}

export interface TableFieldStateActionBase {
  type: TableFieldStateActionType;
}

export interface TableFieldInitializeAction extends TableFieldStateActionBase {
  type: TableFieldStateActionType.INITIALIZE;
  payload: TableField[];
}

export interface TableFieldCreatedAction extends TableFieldStateActionBase {
  type: TableFieldStateActionType.CREATED;
  payload: TableField;
}

export interface TableFieldUpdatedAction extends TableFieldStateActionBase {
  type: TableFieldStateActionType.UPDATED;
  payload: TableField;
}

export interface TableFieldDeletedAction extends TableFieldStateActionBase {
  type: TableFieldStateActionType.DELETED;
  payload: number;
}

export type TableFieldStateAction =
  | TableFieldInitializeAction
  | TableFieldCreatedAction
  | TableFieldUpdatedAction
  | TableFieldDeletedAction;
