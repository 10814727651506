import { Selector } from '@/model/selector';
import { ChangeEvent, FC, useState } from 'react';

const formDefaultValue: Selector = {
  name: '',
};

export interface SelectorsFormComponentProps {
  defaultValue?: Selector;
  onSubmit: (selector: Selector) => Promise<Selector>;
  resetOnSubmit?: boolean;
}

export const SelectorsFormComponent: FC<SelectorsFormComponentProps> = ({
  defaultValue,
  onSubmit,
  resetOnSubmit = true,
}) => {
  const [selector, setSelector] = useState(defaultValue || formDefaultValue);

  const setInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSelector((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetForm = () => setSelector(formDefaultValue);

  const onFormSubmit = () => {
    onSubmit(selector).then(() => {
      if (resetOnSubmit) {
        resetForm();
      }
    });
  };

  return (
    <form className='d-grid gap-3'>
      <div>
        <label
          className='form-label'
          htmlFor='name'
          placeholder='Nombre del selector'
        >
          Nombre
        </label>

        <input
          className='form-control'
          value={selector.name}
          type='text'
          name='name'
          onChange={setInput}
          required
        />
      </div>

      <section className='col-12'>
        <div className='row'>
          <div className='d-grid col-6'>
            <button
              className='btn btn-secondary'
              type='button'
              onClick={resetForm}
            >
              Restablecer
            </button>
          </div>

          <div className='d-grid col-6'>
            <button
              className='btn btn-primary'
              type='button'
              onClick={onFormSubmit}
            >
              Guardar
            </button>
          </div>
        </div>
      </section>
    </form>
  );
};
