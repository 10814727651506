import { queryCategoryByIdApi, updateCategoryApi } from '@/api/category.api';
import { CategoriesFormComponent } from '@/components/CategoriesForm';
import { Category } from '@/model/category';
import { createCategoryUpdatedAction } from '@/store/categories/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const UpdateCategoryPage: FC = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const [category, setCategory] = useState<Category | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let dropResult = false;
    setIsLoading(true);

    queryCategoryByIdApi(+categoryId!)
      .then((foundCategory) => {
        if (dropResult) {
          return;
        }

        setCategory(foundCategory);
      })
      .finally(() => {
        if (dropResult) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      dropResult = true;
    };
  }, [categoryId]);

  const successCategory = (updateCategory: Category) => {
    toast('Categoría actualizada.', {
      type: 'info',
    });

    const { parentCategoryId, formId } = updateCategory;
    const event =
      (parentCategoryId ? `${formId}_${parentCategoryId}_` : `${formId}_`) +
      EventTypes.CATEGORIES;

    Events.publish(event, createCategoryUpdatedAction(updateCategory));
  };

  const onSubmit = (category: Category) => {
    return updateCategoryApi({
      ...category,
      id: +categoryId!,
    }).then((updatedCategory) => {
      successCategory(updatedCategory);

      return updatedCategory;
    });
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <section className='container-fluid'>
      <p className='h1 text-primary fw-bold'>Actualizar Categoría</p>

      <hr className='panel-hr' />

      <CategoriesFormComponent
        defaultValue={category!}
        onSubmit={onSubmit}
        resetOnSubmit={false}
      />
    </section>
  );
};
