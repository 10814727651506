import {
  queryValidationByIdApi,
  updateValidationApi,
} from '@/api/validation.api';
import { ValidationsForm } from '@/components/ValidationsForm';
import { Validation } from '@/model/validation';
import { createValidationUpdatedAction } from '@/store/validations/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const UpdateValidationPage: FC = () => {
  const { validationId } = useParams<{ validationId: string }>();
  const [validation, setValidation] = useState<Validation | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let dropResult = false;
    setIsLoading(true);

    queryValidationByIdApi(+validationId!)
      .then((foundValidation) => {
        if (dropResult) {
          return;
        }

        setValidation(foundValidation);
      })
      .finally(() => {
        if (dropResult) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      dropResult = true;
    };
  }, [validationId]);

  const onSuccess = (validation: Validation) => {
    toast('Validación Actualizada.', {
      type: 'info',
    });

    Events.publish(
      EventTypes.VALIDATIONS,
      createValidationUpdatedAction(validation)
    );
  };

  const onSubmit = (validation: Validation) => {
    return updateValidationApi({ ...validation, id: +validationId! }).then(
      (updatedValidation) => {
        onSuccess(updatedValidation);
        return updatedValidation;
      }
    );
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <section className='container-fluid'>
      <p className='h1 text-primary fw-bold'>Actualizar plantilla</p>
      <hr className='panel-hr' />

      <ValidationsForm
        defaultValue={validation!}
        onSubmit={onSubmit}
        resetOnSubmit={false}
      />
    </section>
  );
};
