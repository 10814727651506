import { createSelectorApi } from '@/api/selectors.api';
import { SelectorsFormComponent } from '@/components/SelectorsForm';
import { Selector } from '@/model/selector';
import { createSelectorCreatedAction } from '@/store/selectors/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC } from 'react';
import { toast } from 'react-toastify';

export const CreateSelectorPage: FC = () => {
  const onSuccess = (selector: Selector) => {
    toast('Selector creado.', {
      type: 'success',
    });

    Events.publish(EventTypes.SELECTORS, createSelectorCreatedAction(selector));
  };

  const onSubmit = (selector: Selector) => {
    return createSelectorApi(selector).then((newSelector) => {
      onSuccess(newSelector);
      return newSelector;
    });
  };

  return (
    <section className='container-fluid'>
      <p className='h1 text-primary fw-bold'>Nuevo selector</p>
      <hr className='panel-hr' />

      <SelectorsFormComponent onSubmit={onSubmit} />
    </section>
  );
};
