import { deleteFieldEnablementApi } from '@/api/fieldEnablement.api';
import { EnableItem } from '@/model/enablement';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC, useState } from 'react';
import { AiFillCaretRight } from 'react-icons/ai';
import { BiMinus } from 'react-icons/bi';
import { toast } from 'react-toastify';

export interface EnablementeItemsComponentProps {
  title: string;
  enablementItems: EnableItem[];
  fieldId: number;
  formId: number;
  elementType: 'field' | 'category';
  enable: boolean;
}

export const EnablementeItemsComponent: FC<EnablementeItemsComponentProps> = ({
  title,
  enablementItems,
  fieldId,
  formId,
  elementType,
  enable,
}) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    setVisible((prev) => !prev);
  };

  const onDelete = (enableItem: EnableItem) => {
    deleteFieldEnablementApi(fieldId, {
      elementType,
      id: enableItem.id,
      enable,
    })
      .then(() => {
        toast('Registro eliminado con éxito', { type: 'success' });
        Events.publish(`${formId}_${fieldId}_${EventTypes.FIELD_ENABLEMENT}`, {
          type: 'delete',
          name: enableItem.name,
          enablementRequest: { id: enableItem.id, elementType, enable },
        });
      })
      .catch(() => {
        toast('Error al eliminar el registro', { type: 'error' });
      });
  };

  return (
    <div>
      <div className='d-flex align-items-center'>
        <span onClick={toggleVisible} className='d-flex align-items-center'>
          <AiFillCaretRight
            className={`caret ${visible ? 'caret--active' : ''} text-primary`}
          />
        </span>

        <span className='h5 mb-0 text-primary d-flex align-items-center gap-2'>
          {title}
        </span>
      </div>

      {visible && (
        <ul>
          {enablementItems.map((item) => (
            <li key={item.id} className='ls-none'>
              <div className='d-flex align-items-center'>
                <span className='h5 mb-0 d-flex align-items-center gap-2'>
                  {item.name}
                </span>

                <button
                  className='btn btn-danger btn-circle ms-2'
                  title='Eliminar registro'
                  onClick={() => onDelete(item)}
                >
                  <BiMinus size={24} className='crud-btn' />
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
