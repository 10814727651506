import { Form } from '@/model/form';
import { ChangeEvent, FC, useState } from 'react';

const formDefaultValue: Form = {
  name: '',
  image: '',
  color: '',
};

export interface FormsFormComponentProps {
  defaultValue?: Form;
  onSubmit: (form: Form) => Promise<Form>;
  resetOnSubmit?: boolean;
}

export const FormsFormComponent: FC<FormsFormComponentProps> = ({
  defaultValue,
  onSubmit,
  resetOnSubmit = true,
}) => {
  const [form, setForm] = useState(defaultValue || formDefaultValue);

  const setInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetForm = () => setForm(formDefaultValue);

  const onFormSubmit = () => {
    onSubmit(form).then(() => {
      if (resetOnSubmit) {
        resetForm();
      }
    });
  };

  return (
    <form className='d-grid gap-3'>
      <div>
        <label
          className='form-label'
          htmlFor='name'
          placeholder='Nombre de la plantilla'
        >
          Nombre
        </label>

        <input
          className='form-control'
          value={form.name}
          type='text'
          name='name'
          onChange={setInput}
          required
        />
      </div>

      {/* <div>
          <label className='form-label' htmlFor='imagen'>
            Imagen
          </label>

          <input
            className='form-control'
            type='file'
            value={form.image}
            name='image'
            onChange={setInput}
          />
        </div> */}

      <div>
        <label className='form-label' htmlFor='color'>
          Color
        </label>

        <input
          className='form-control'
          value={form.color}
          type='color'
          name='color'
          onChange={setInput}
        />
      </div>

      <section className='col-12'>
        <div className='row'>
          <div className='d-grid col-6'>
            <button
              className='btn btn-secondary'
              type='button'
              onClick={resetForm}
            >
              Restablecer
            </button>
          </div>

          <div className='d-grid col-6'>
            <button
              className='btn btn-primary'
              type='button'
              onClick={onFormSubmit}
            >
              Guardar
            </button>
          </div>
        </div>
      </section>
    </form>
  );
};
