import { Form } from '@/model/form';
import { AdminClient } from './AdminClient.config';

const formsBaseUrl = '/forms';

export const createFormApi = (form: Form) =>
  AdminClient.post<Form>(formsBaseUrl, form).then((response) => response.data);

export const updateFormApi = (form: Form) =>
  AdminClient.put<Form>(`${formsBaseUrl}/${form.id}`, form).then(
    (response) => response.data
  );

export const queryFormByIdApi = (formId: number) =>
  AdminClient.get<Form | null>(`${formsBaseUrl}/${formId}`).then(
    (response) => response.data || null
  );

export const queryFormsApi = () =>
  AdminClient.get<Form[]>(formsBaseUrl).then((response) => response.data || []);

export const deleteFormApi = (formId: number) =>
  AdminClient.delete(`${formsBaseUrl}/${formId}`).then(() => {});
