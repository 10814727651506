import { deleteValidationApi, queryValidationsApi } from '@/api/validation.api';
import { FormsContext } from '@/context/Forms';
import {
  createValidationDeletedAction,
  createValidationInitializeAction,
} from '@/store/validations/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC, useContext, useEffect, useState } from 'react';
import { BiMinus, BiPlus, BiSolidPencil } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ListValidationsPage: FC = () => {
  const { validations } = useContext(FormsContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let dropResult = false;
    setIsLoading(true);

    queryValidationsApi()
      .then((validationsList) => {
        if (dropResult) {
          return;
        }

        Events.publish(
          EventTypes.VALIDATIONS,
          createValidationInitializeAction(validationsList)
        );
      })
      .finally(() => {
        if (dropResult) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      dropResult = true;
    };
  }, []);

  const onDeleteValidation = (validationId: number) => {
    deleteValidationApi(validationId).then(() => {
      toast('Validación eliminada.', { type: 'error' });
      Events.publish(
        EventTypes.VALIDATIONS,
        createValidationDeletedAction(validationId)
      );
    });
  };

  return (
    <section className='container-fluid'>
      <header className='d-flex justify-content-between'>
        <p className='h1 text-primary fw-bold'>Validaciones</p>

        <Link to='crear'>
          <button className='btn btn-primary fs-4 d-flex align-items-center'>
            <BiPlus size={28} />

            <span>Crear validación</span>
          </button>
        </Link>
      </header>

      <hr className='panel-hr' />

      {isLoading ? (
        <div>Cargando...</div>
      ) : (
        <table className='table'>
          <thead>
            <tr className='table-primary'>
              <th>Nombre</th>
              <th>Mensaje</th>
              <th>Validación</th>
              <th>Acciones</th>
            </tr>
          </thead>

          <tbody>
            {validations.map((validation) => (
              <tr key={validation.id}>
                <td>{validation.name}</td>
                <td>{validation.message}</td>
                <td>{validation.validation}</td>
                <td>
                  <Link to={`${validation.id}`} className='ms-2'>
                    <button
                      className='btn btn-primary btn-circle'
                      title='Actualizar plantilla'
                    >
                      <BiSolidPencil size={16} className='crud-btn' />
                    </button>
                  </Link>

                  <button
                    className='btn btn-danger btn-circle ms-2'
                    title='Eliminar validación'
                    onClick={() => onDeleteValidation(validation.id!)}
                  >
                    <BiMinus size={24} className='crud-btn' />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </section>
  );
};
