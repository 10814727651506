function subscribe<T>(
  eventName: string,
  listener: (event: CustomEvent<T>) => void
) {
  document.addEventListener(eventName, listener as any);
}

function unsubscribe<T>(
  eventName: string,
  listener: (event: CustomEvent<T>) => void
) {
  document.removeEventListener(eventName, listener as any);
}

function publish<T>(eventName: string, data: T) {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

const Events = Object.freeze({
  subscribe,
  unsubscribe,
  publish,
});

export default Events;
