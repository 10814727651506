import { FieldType } from '@/model/fieldType';
import { FC } from 'react';
import { BiTable } from 'react-icons/bi';
import { BsCalendarDate, BsCalendarMonth, BsClock } from 'react-icons/bs';
import { CgSelect } from 'react-icons/cg';
import { FaLocationDot, FaRegMoneyBill1, FaToggleOff } from 'react-icons/fa6';
import { MdAddAPhoto } from 'react-icons/md';
import { TbAbc, TbDecimal, TbNumbers } from 'react-icons/tb';

export interface FieldIconAdapterComponentProps {
  fieldType: FieldType;
}

export const FieldIconAdapterComponent: FC<FieldIconAdapterComponentProps> = ({
  fieldType,
}) => {
  if (!fieldType) {
    return null;
  }

  switch (fieldType) {
    case FieldType.BOOLEAN:
      return <FaToggleOff />;
    case FieldType.CAPTURE:
      return <MdAddAPhoto />;
    case FieldType.DATE:
      return <BsCalendarDate />;
    case FieldType.FLOAT:
      return <TbDecimal />;
    case FieldType.HOUR:
      return <BsClock />;
    case FieldType.INTEGER:
      return <TbNumbers />;
    case FieldType.LATITUDE:
    case FieldType.LONGITUDE:
      return <FaLocationDot />;
    case FieldType.MONTHS_MULTIPLE:
      return <BsCalendarMonth />;
    case FieldType.MONEY:
      return <FaRegMoneyBill1 />;
    case FieldType.SELECT:
      return <CgSelect />;
    case FieldType.TABLE:
      return <BiTable />;
    default:
      return <TbAbc />;
  }
};
