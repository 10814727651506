import { queryFormByIdApi, updateFormApi } from '@/api/forms.api';
import { FormsFormComponent } from '@/components/FormsForm';
import { Form } from '@/model/form';
import { createFormUpdatedAction } from '@/store/forms/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const UpdateFormPage: FC = () => {
  const { formId } = useParams<{ formId: string }>();
  const [form, setForm] = useState<Form | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let dropResult = false;
    setIsLoading(true);

    queryFormByIdApi(+formId!)
      .then((foundForm) => {
        if (dropResult) {
          return;
        }

        setForm(foundForm);
      })
      .finally(() => {
        if (dropResult) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      dropResult = true;
    };
  }, [formId]);

  const onSuccess = (form: Form) => {
    toast('Plantilla Actualizada.', {
      type: 'info',
    });

    Events.publish(EventTypes.FORMS, createFormUpdatedAction(form));
  };

  const onSubmit = (form: Form) => {
    return updateFormApi({ ...form, id: +formId! }).then((updatedForm) => {
      onSuccess(updatedForm);
      return updatedForm;
    });
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <section className='container-fluid'>
      <p className='h1 text-primary fw-bold'>Actualizar plantilla</p>
      <hr className='panel-hr' />

      <FormsFormComponent
        defaultValue={form!}
        onSubmit={onSubmit}
        resetOnSubmit={false}
      />
    </section>
  );
};
