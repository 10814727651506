import { deleteFormApi } from '@/api/forms.api';
import { ConfirmDialogComponent } from '@/components/ConfirmDialog';
import { CategoryProvider } from '@/context/Categories';
import { FormContext } from '@/context/Forms/FormContext';
import { createFormDeletedAction } from '@/store/forms/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC, useContext, useState } from 'react';
import { AiFillCaretRight } from 'react-icons/ai';
import { BiMinus, BiPlus, BiSolidPencil } from 'react-icons/bi';
import { FaFileLines } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CategoryComponent } from './Category';

export const FormComponent: FC = () => {
  const { form, categories, queryCategories } = useContext(FormContext);
  const [isOpen, setIsOpen] = useState(false);

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    if (!visible) {
      queryCategories();
    }

    setVisible((prev) => !prev);
  };

  const onDeleteForm = () => {
    deleteFormApi(form.id!).then(() => {
      toast('Plantilla eliminada.', { type: 'error' });
      Events.publish(EventTypes.FORMS, createFormDeletedAction(form.id!));
    });
  };

  return (
    <section>
      <div className='d-flex align-items-center'>
        <span onClick={toggleVisible}>
          <AiFillCaretRight
            className={`caret ${visible ? 'caret--active' : ''} text-primary`}
          />
        </span>

        <span className='h4 mb-0 text-primary d-flex align-items-center gap-2'>
          <FaFileLines />
          {form?.name}
        </span>

        <Link to={`/plantillas/${form.id}`} className='ms-2'>
          <button
            className='btn btn-dark btn-circle'
            title='Actualizar plantilla'
          >
            <BiSolidPencil size={16} className='crud-btn' />
          </button>
        </Link>

        <Link to={`/plantillas/${form.id}/categorias`} className='ms-2'>
          <button
            className='btn btn-primary btn-circle'
            title='Crear categoría'
          >
            <BiPlus size={24} className='crud-btn' />
          </button>
        </Link>

        <button
          className='btn btn-danger btn-circle ms-2'
          title='Eliminar plantilla'
          onClick={() => setIsOpen(true)}
        >
          <BiMinus size={24} className='crud-btn' />
        </button>
      </div>

      <ul className={`ls-none ${visible ? '' : 'visually-hidden'}`}>
        {categories.map((category) => (
          <li key={category.id}>
            <CategoryProvider category={category}>
              <CategoryComponent />
            </CategoryProvider>
          </li>
        ))}
      </ul>

      <ConfirmDialogComponent
        isOpen={isOpen}
        title='Eliminar plantilla'
        message='¿Está seguro que desea eliminar esta plantilla?'
        onCancel={() => setIsOpen(false)}
        onConfirm={onDeleteForm}
      />
    </section>
  );
};
