import { Enablement, EnablementRequest } from '@/model/enablement';
import { AdminClient } from './AdminClient.config';

const fieldsBaseUrl = '/fields';
const enablementBaseUrl = 'enablement';

export const queryFieldEnablementApi = (fieldId: number) =>
  AdminClient.get<Enablement>(
    `${fieldsBaseUrl}/${fieldId}/${enablementBaseUrl}`
  ).then((response) => response.data || null);

export const createFieldEnablementApi = (
  fieldId: number,
  payload: EnablementRequest
) =>
  AdminClient.post<void>(
    `${fieldsBaseUrl}/${fieldId}/${enablementBaseUrl}`,
    payload
  ).then((response) => {});

export const deleteFieldEnablementApi = (
  fieldId: number,
  payload: EnablementRequest
) =>
  AdminClient.put<void>(
    `${fieldsBaseUrl}/${fieldId}/${enablementBaseUrl}`,
    payload
  ).then((response) => {});
