import { createTableFieldEnablementApi } from '@/api/tableFieldEnablement.api';
import { queryTableFieldsApi } from '@/api/tableFields.api';
import { EnablementRequest } from '@/model/enablement';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

const defaultValue: EnablementRequest = {
  id: undefined,
  elementType: undefined,
  enable: undefined,
};

export interface AutocompleteOption {
  label: string;
  id: number;
}

export interface AddTableEnablementComponentProps {
  isOpen: boolean;
  onClose: () => void;
  fieldId: number;
  tableFieldId: number;
}

export const AddTableEnablementComponent: FC<
  AddTableEnablementComponentProps
> = ({ isOpen, onClose, fieldId, tableFieldId }) => {
  const [enablement, setEnablement] = useState<EnablementRequest>(defaultValue);
  const [options, setOptions] = useState<AutocompleteOption[]>([]);

  const setInput = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setEnablement((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetForm = () => setEnablement(defaultValue);

  const onFormSubmit = () => {
    createTableFieldEnablementApi(fieldId, enablement)
      .then(() => {
        toast('Registro creado con éxito', { type: 'success' });
        setOptions([]);
        resetForm();
        onClose();

        Events.publish(
          `${tableFieldId}_${fieldId}_${EventTypes.TABLE_FIELD_ENABLEMENT}`,
          {
            type: 'create',
            name: options.find((option) => option.id === enablement.id)?.label!,
            enablementRequest: enablement,
          }
        );
      })
      .catch(() => {
        toast('Error al crear el registro', { type: 'error' });
      });
  };

  const selectedElement: AutocompleteOption | null = useMemo(
    () => options.find((option) => option.id === enablement.id) || null,
    [enablement, options]
  );

  useEffect(() => {
    if (!isOpen) {
      setOptions([]);
      return;
    }

    let dropResponse = false;

    queryTableFieldsApi({ fieldId: tableFieldId }).then((fields) => {
      if (dropResponse) return;

      setOptions(
        fields
          .filter((item) => item.id !== fieldId)
          .map((field) => ({
            label: field.name,
            id: field.id!,
          }))
      );
    });

    return () => {
      dropResponse = true;
    };
  }, [tableFieldId, fieldId, isOpen]);

  return (
    <form>
      <Dialog fullWidth maxWidth='sm' open={isOpen} onClose={onClose}>
        <DialogContent className='d-grid gap-3'>
          <div>
            <label
              className='form-label'
              htmlFor='enable'
              placeholder='Habilitar/Deshabilitar'
            >
              Habilitar/Deshabilitar
            </label>

            <select
              className='form-select'
              name='enable'
              onChange={setInput}
              value={enablement.enable as any}
              required
            >
              <option value=''>Seleccione una opción</option>

              <option value={true as any}>Habilitar</option>

              <option value={false as any}>Deshabilitar</option>
            </select>
          </div>

          <div>
            <label className='form-label' htmlFor='id' placeholder='id'>
              Categoría/Campo
            </label>

            <Autocomplete
              id='id'
              options={options}
              value={selectedElement}
              onChange={(_, value) =>
                setEnablement((prev) => ({ ...prev, id: value?.id }))
              }
              renderInput={(params) => (
                <TextField {...params} label='Elemento' />
              )}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <div className='col-12'>
            <div className='row'>
              <div className='d-grid col-4'>
                <button
                  className='btn btn-secondary'
                  type='button'
                  onClick={resetForm}
                >
                  Restablecer
                </button>
              </div>

              <div className='d-grid col-4'>
                <button
                  className='btn btn-primary w-100'
                  type='button'
                  onClick={onFormSubmit}
                >
                  Guardar
                </button>
              </div>

              <div className='d-grid col-4'>
                <button
                  className='btn btn-danger w-100'
                  type='button'
                  onClick={onClose}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </form>
  );
};
