import { deleteSelectorApi, querySelectorsApi } from '@/api/selectors.api';
import { FormsContext } from '@/context/Forms';
import {
  createSelectorDeletedAction,
  createSelectorInitializeAction,
} from '@/store/selectors/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC, useContext, useEffect, useState } from 'react';
import { BiMinus, BiPlus, BiSearch, BiSolidPencil } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ListselectorsPage: FC = () => {
  const { selectors } = useContext(FormsContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let dropResult = false;
    setIsLoading(true);

    querySelectorsApi()
      .then((selectorsList) => {
        if (dropResult) {
          return;
        }

        Events.publish(
          EventTypes.SELECTORS,
          createSelectorInitializeAction(selectorsList)
        );
      })
      .finally(() => {
        if (dropResult) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      dropResult = true;
    };
  }, []);

  const onDeleteSelector = (selectorId: number) => {
    deleteSelectorApi(selectorId).then(() => {
      toast('Selector eliminado.', { type: 'error' });
      Events.publish(
        EventTypes.SELECTORS,
        createSelectorDeletedAction(selectorId)
      );
    });
  };

  return (
    <section className='container-fluid'>
      <header className='d-flex justify-content-between'>
        <p className='h1 text-primary fw-bold'>Selectores</p>

        <Link to='crear'>
          <button className='btn btn-primary fs-4 d-flex align-items-center'>
            <BiPlus size={28} />

            <span>Crear selector</span>
          </button>
        </Link>
      </header>

      <hr className='panel-hr' />

      {isLoading ? (
        <div>Cargando...</div>
      ) : (
        <table className='table'>
          <thead>
            <tr className='table-primary'>
              <th>ID</th>
              <th>Nombre</th>
              <th>Acciones</th>
            </tr>
          </thead>

          <tbody>
            {selectors.map((selector) => (
              <tr key={selector.id}>
                <td>{selector.id}</td>
                <td>{selector.name}</td>
                <td>
                  <Link to={`${selector.id}`} className='ms-2'>
                    <button
                      className='btn btn-primary btn-circle'
                      title='Actualizar selector'
                    >
                      <BiSolidPencil size={16} className='crud-btn' />
                    </button>
                  </Link>

                  <button
                    className='btn btn-danger btn-circle ms-2'
                    title='Eliminar selector'
                    onClick={() => onDeleteSelector(selector.id!)}
                  >
                    <BiMinus size={24} className='crud-btn' />
                  </button>

                  <Link to={`/selectores/${selector.id}/opciones`}>
                    <button
                      className='btn btn-dark btn-circle ms-2'
                      title='Opciones del selector'
                    >
                      <BiSearch size={18} className='crud-btn' />
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </section>
  );
};
