import { createFormCategoryApi } from '@/api/formCategories.api';
import { CategoriesFormComponent } from '@/components/CategoriesForm';
import { Category } from '@/model/category';
import { createCategoryCreatedAction } from '@/store/categories/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const CreateCategoryPage: FC = () => {
  const { formId } = useParams();
  const [searchParams] = useSearchParams('parentCategoryId=0');

  const successCategory = (category: Category) => {
    toast('Categoría creada.', {
      type: 'success',
    });

    const { parentCategoryId, formId } = category;
    const event =
      (parentCategoryId ? `${formId}_${parentCategoryId}_` : `${formId}_`) +
      EventTypes.CATEGORIES;

    Events.publish(event, createCategoryCreatedAction(category));
  };

  const onSubmit = (category: Category) => {
    return createFormCategoryApi({
      ...category,
      formId: +formId!,
      parentCategoryId: +searchParams.get('parentCategoryId')! || null,
    }).then((newCategory) => {
      successCategory(newCategory);

      return newCategory;
    });
  };

  return (
    <section className='container-fluid'>
      <p className='h1 text-primary fw-bold'>Nueva Categoría</p>

      <hr className='panel-hr' />

      <CategoriesFormComponent onSubmit={onSubmit} />
    </section>
  );
};
