import { createSelectorDetailApi } from '@/api/selectorDetails.api';
import { SelectorDetailsFormComponent } from '@/components/SelectorDetailsForm';
import { SelectorDetail } from '@/model/selectorDetail';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const CreateSelectorDetailPage: FC = () => {
  const { selectorId } = useParams();

  const successSelectorDetail = () => {
    toast('Opción creada.', {
      type: 'success',
    });
  };

  const onSubmit = (selectorDetail: SelectorDetail) => {
    return createSelectorDetailApi({
      ...selectorDetail,
      selectorId: +selectorId!,
    }).then((newSelectorDetail) => {
      successSelectorDetail();

      return newSelectorDetail;
    });
  };

  return (
    <section className='container-fluid'>
      <p className='h1 text-primary fw-bold'>Nueva opción</p>

      <hr className='panel-hr' />

      <SelectorDetailsFormComponent onSubmit={onSubmit} />
    </section>
  );
};
