import {
  deleteSelectorDetailApi,
  querySelectorDetailsApi,
} from '@/api/selectorDetails.api';
import { SelectorDetail } from '@/model/selectorDetail';
import { FC, useEffect, useState } from 'react';
import { BiMinus, BiPlus, BiSolidPencil } from 'react-icons/bi';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ListSelectorDetailsPage: FC = () => {
  const { selectorId } = useParams<{ selectorId: string }>();
  const [details, setDetails] = useState<SelectorDetail[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let dropResult = false;
    setIsLoading(true);

    querySelectorDetailsApi(+selectorId!)
      .then((detailsList) => {
        if (dropResult) {
          return;
        }

        setDetails(detailsList);
      })
      .finally(() => {
        if (dropResult) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      dropResult = true;
    };
  }, [selectorId]);

  const onDeleteDetail = (detailId: number) => {
    deleteSelectorDetailApi(detailId).then(() => {
      toast('Opción eliminada.', { type: 'error' });

      setDetails((prev) => prev.filter((detail) => detail.id !== detailId));
    });
  };

  return (
    <section className='container-fluid'>
      <header className='d-flex justify-content-between'>
        <p className='h1 text-primary fw-bold'>Opciones del selector</p>

        <Link to='crear'>
          <button className='btn btn-primary fs-4 d-flex align-items-center'>
            <BiPlus size={28} />

            <span>Crear opción</span>
          </button>
        </Link>
      </header>

      <hr className='panel-hr' />

      {isLoading ? (
        <div>Cargando...</div>
      ) : (
        <table className='table'>
          <thead>
            <tr className='table-primary'>
              <th>ID</th>
              <th>Etiqueta</th>
              <th>Valor</th>
              <th>Orden</th>
              <th>Acciones</th>
            </tr>
          </thead>

          <tbody>
            {details.map((detail) => (
              <tr key={detail.id}>
                <td>{detail.id}</td>
                <td>{detail.label}</td>
                <td>{detail.value}</td>
                <td>{detail.order}</td>
                <td>
                  <Link to={`${detail.id}`} className='ms-2'>
                    <button
                      className='btn btn-primary btn-circle'
                      title='Actualizar opción'
                    >
                      <BiSolidPencil size={16} className='crud-btn' />
                    </button>
                  </Link>

                  <button
                    className='btn btn-danger btn-circle ms-2'
                    title='Eliminar opción'
                    onClick={() => onDeleteDetail(detail.id!)}
                  >
                    <BiMinus size={24} className='crud-btn' />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </section>
  );
};
