import {
  querySelectorDetailByIdApi,
  updateSelectorDetailApi,
} from '@/api/selectorDetails.api';
import { SelectorDetailsFormComponent } from '@/components/SelectorDetailsForm';
import { SelectorDetail } from '@/model/selectorDetail';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const UpdateSelectorDetailPage: FC = () => {
  const { detailId } = useParams<{ detailId: string }>();
  const [selectorDetail, setSelectorDetail] = useState<SelectorDetail | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let dropResult = false;
    setIsLoading(true);

    querySelectorDetailByIdApi(+detailId!)
      .then((foundSelectorDetail) => {
        if (dropResult) {
          return;
        }

        setSelectorDetail(foundSelectorDetail);
      })
      .finally(() => {
        if (dropResult) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      dropResult = true;
    };
  }, [detailId]);

  const successSelectorDetail = () => {
    toast('Opción actualizada.', {
      type: 'info',
    });
  };

  const onSubmit = (selectorDetail: SelectorDetail) => {
    return updateSelectorDetailApi({
      ...selectorDetail,
      id: +detailId!,
    }).then((updatedSelectorDetail) => {
      successSelectorDetail();

      return updatedSelectorDetail;
    });
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <section className='container-fluid'>
      <p className='h1 text-primary fw-bold'>Actualizar Opción</p>

      <hr className='panel-hr' />

      <SelectorDetailsFormComponent
        defaultValue={selectorDetail!}
        onSubmit={onSubmit}
        resetOnSubmit={false}
      />
    </section>
  );
};
