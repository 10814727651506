import { Form } from '@/model/form';
import {
  FormCreatedAction,
  FormDeletedAction,
  FormInitializeAction,
  FormStateActionType,
  FormUpdatedAction,
  IFormState,
} from './model';

export const createFormInitializeAction = (
  forms: Form[]
): FormInitializeAction => ({
  type: FormStateActionType.INITIALIZE,
  payload: forms,
});

export const formInitializeAction = (
  state: IFormState,
  action: FormInitializeAction
): IFormState => ({
  ...state,
  forms: action.payload.slice(),
});

export const createFormCreatedAction = (form: Form): FormCreatedAction => ({
  type: FormStateActionType.CREATED,
  payload: form,
});

export const formCreatedAction = (
  state: IFormState,
  action: FormCreatedAction
): IFormState => ({
  ...state,
  forms: state.forms.concat(action.payload),
});

export const createFormUpdatedAction = (form: Form): FormUpdatedAction => ({
  type: FormStateActionType.UPDATED,
  payload: form,
});

export const formUpdatedAction = (
  state: IFormState,
  { payload }: FormUpdatedAction
): IFormState => ({
  ...state,
  forms: state.forms.map((form) => (form.id === payload.id ? payload : form)),
});

export const createFormDeletedAction = (id: number): FormDeletedAction => ({
  type: FormStateActionType.DELETED,
  payload: id,
});

export const formDeletedAction = (
  state: IFormState,
  action: FormDeletedAction
): IFormState => ({
  ...state,
  forms: state.forms.filter((form) => form.id !== action.payload),
});
