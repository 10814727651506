import { Dispatch } from 'react';
import {
  selectorCreatedAction,
  selectorDeletedAction,
  selectorInitializeAction,
  selectorUpdatedAction,
} from './actions';
import {
  ISelectorState,
  SelectorStateAction,
  SelectorStateActionType,
} from './model';

const defaultState: ISelectorState = { selectors: [] };

const reducer = (
  state: ISelectorState = defaultState,
  { detail: action }: CustomEvent<SelectorStateAction>
) => {
  switch (action.type) {
    case SelectorStateActionType.INITIALIZE:
      return selectorInitializeAction(state, action);
    case SelectorStateActionType.CREATED:
      return selectorCreatedAction(state, action);
    case SelectorStateActionType.UPDATED:
      return selectorUpdatedAction(state, action);
    case SelectorStateActionType.DELETED:
      return selectorDeletedAction(state, action);
    default:
      return { ...state };
  }
};

const eventsHanlder = (
  event: CustomEvent<SelectorStateAction>,
  dispatcher: Dispatch<SelectorStateAction>
) => dispatcher(event.detail);

const SelectorState = Object.freeze({ reducer, defaultState, eventsHanlder });

export default SelectorState;
