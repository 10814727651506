import { deleteCategoryApi } from '@/api/category.api';
import { queryCategoryFieldsApi } from '@/api/categoryFields.api';
import { queryFormCategoriesApi } from '@/api/formCategories.api';
import { BaseComponent } from '@/model/BaseComponent';
import { Category } from '@/model/category';
import CategoryState from '@/store/categories';
import {
  createCategoryDeletedAction,
  createCategoryInitializeAction,
} from '@/store/categories/actions';
import FieldState from '@/store/fields';
import { createFieldInitializeAction } from '@/store/fields/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { noOpFn } from '@/util/Functions.utils';
import {
  FC,
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';

export interface CategoryContextType {
  category: Category;
  subcategories: Category[];
  querySubcategories: () => void;
  fields: any[];
  queryFields: () => void;
  deleteCategory: () => Promise<void>;
}

export const CategoryContext = createContext<CategoryContextType>({
  category: {} as any,
  subcategories: [],
  querySubcategories: noOpFn,
  fields: [],
  queryFields: noOpFn,
  deleteCategory: () => Promise.resolve(),
});

export interface CategoryProviderProps extends BaseComponent {
  category: Category;
}

export const CategoryProvider: FC<CategoryProviderProps> = ({
  category,
  children,
}) => {
  const [{ categories: subcategories }, subcategoriesDispatcher] = useReducer(
    CategoryState.reducer,
    CategoryState.defaultState
  );
  const [loadSubCategories, setLoadSubCategories] = useState(true);
  const [{ fields }, fieldsDispatcher] = useReducer(
    FieldState.reducer,
    FieldState.defaultState
  );
  const [loadFields, setLoadFields] = useState(true);

  const querySubcategories = useCallback(() => {
    if (!loadSubCategories) return;

    setLoadSubCategories(false);

    queryFormCategoriesApi({
      formId: category.formId!,
      parentCategoryId: category.id,
    })
      .then((foundCategories) => {
        Events.publish(
          `${category.formId}_${category.id}_${EventTypes.CATEGORIES}`,
          createCategoryInitializeAction(foundCategories || [])
        );
        setLoadSubCategories(false);
      })
      .catch(() => {
        setLoadSubCategories(true);
      });
  }, [category, loadSubCategories]);

  const queryFields = useCallback(() => {
    if (!loadFields) return;

    setLoadFields(false);

    queryCategoryFieldsApi(category.id!)
      .then((foundFields) => {
        Events.publish(
          `${category.formId}_${category.id}_${EventTypes.FIELDS}`,
          createFieldInitializeAction(foundFields || [])
        );
        setLoadFields(false);
      })
      .catch(() => {
        setLoadFields(true);
      });
  }, [category, loadFields]);

  const deleteCategory = () => {
    const { id, formId, parentCategoryId } = category;
    return deleteCategoryApi(id!).then(() => {
      const event =
        (!!parentCategoryId ? `${formId}_${parentCategoryId}_` : `${formId}_`) +
        EventTypes.CATEGORIES;

      Events.publish(event, createCategoryDeletedAction(id!));
    });
  };

  useEffect(() => {
    const { formId, id } = category;
    Events.subscribe(
      `${formId}_${id}_${EventTypes.CATEGORIES}`,
      subcategoriesDispatcher
    );
    Events.subscribe(`${formId}_${id}_${EventTypes.FIELDS}`, fieldsDispatcher);

    return () => {
      Events.unsubscribe(
        `${formId}_${id}_${EventTypes.CATEGORIES}`,
        subcategoriesDispatcher
      );
      Events.unsubscribe(
        `${formId}_${id}_${EventTypes.FIELDS}`,
        fieldsDispatcher
      );
    };
  }, [category]);

  return (
    <CategoryContext.Provider
      value={{
        category,
        subcategories,
        querySubcategories,
        fields,
        queryFields,
        deleteCategory,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
