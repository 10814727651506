import { Dispatch } from 'react';
import {
  fieldCreatedAction,
  fieldDeletedAction,
  fieldInitializeAction,
  fieldUpdatedAction,
} from './actions';
import { FieldStateAction, FieldStateActionType, IFieldState } from './model';

const defaultState: IFieldState = { fields: [] };

const reducer = (
  state: IFieldState = defaultState,
  { detail: action }: CustomEvent<FieldStateAction>
) => {
  switch (action.type) {
    case FieldStateActionType.INITIALIZE:
      return fieldInitializeAction(state, action);
    case FieldStateActionType.CREATED:
      return fieldCreatedAction(state, action);
    case FieldStateActionType.UPDATED:
      return fieldUpdatedAction(state, action);
    case FieldStateActionType.DELETED:
      return fieldDeletedAction(state, action);
    default:
      return { ...state };
  }
};

const eventsHanlder = (
  event: CustomEvent<FieldStateAction>,
  dispatcher: Dispatch<FieldStateAction>
) => dispatcher(event.detail);

const FieldState = Object.freeze({ reducer, defaultState, eventsHanlder });

export default FieldState;
