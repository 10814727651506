import { queryFormCategoriesApi } from '@/api/formCategories.api';
import { BaseComponent } from '@/model/BaseComponent';
import { Category } from '@/model/category';
import { Form } from '@/model/form';
import CategoryState from '@/store/categories';
import { createCategoryInitializeAction } from '@/store/categories/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { noOpFn } from '@/util/Functions.utils';
import {
  FC,
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';

export interface FormContextType {
  form: Form;
  categories: Category[];
  queryCategories: () => void;
}

export const FormContext = createContext<FormContextType>({
  form: {} as any,
  categories: [],
  queryCategories: noOpFn,
});

export interface FormProviderProps extends BaseComponent {
  form: Form;
}

export const FormProvider: FC<FormProviderProps> = ({ form, children }) => {
  const [{ categories }, categoriesDispatcher] = useReducer(
    CategoryState.reducer,
    CategoryState.defaultState
  );
  const [loadCategories, setLoadCategories] = useState(true);

  const queryCategories = useCallback(() => {
    if (!loadCategories) return;

    setLoadCategories(false);

    queryFormCategoriesApi({ formId: form.id!, onlyParent: true })
      .then((foundCategories) => {
        Events.publish(
          `${form.id}_${EventTypes.CATEGORIES}`,
          createCategoryInitializeAction(foundCategories || [])
        );
        setLoadCategories(false);
      })
      .catch(() => {
        setLoadCategories(true);
      });
  }, [form, loadCategories]);

  useEffect(() => {
    const { id } = form;
    Events.subscribe(`${id}_${EventTypes.CATEGORIES}`, categoriesDispatcher);

    return () => {
      Events.unsubscribe(
        `${id}_${EventTypes.CATEGORIES}`,
        categoriesDispatcher
      );
    };
  }, [form]);

  return (
    <FormContext.Provider
      value={{
        form,
        categories,
        queryCategories,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
