import { createContext, FC, useEffect, useReducer } from 'react';

import { queryFormsApi } from '@/api/forms.api';
import { querySelectorsApi } from '@/api/selectors.api';
import { queryValidationsApi } from '@/api/validation.api';
import { useQuery } from '@/hooks/UseQuery.hook';
import { BaseComponent } from '@/model/BaseComponent';
import { Form } from '@/model/form';
import { Selector } from '@/model/selector';
import { Validation } from '@/model/validation';
import FormState from '@/store/forms';
import { createFormInitializeAction } from '@/store/forms/actions';
import SelectorState from '@/store/selectors';
import { createSelectorInitializeAction } from '@/store/selectors/actions';
import ValidationState from '@/store/validations';
import { createValidationInitializeAction } from '@/store/validations/actions';
import Events from '@/util/Events';
import EventTypes from '@/util/EventTypes.constants';

export interface FormsContextType {
  forms: Form[];
  validations: Validation[];
  selectors: Selector[];
}

export const FormsContext = createContext<FormsContextType>({
  forms: [],
  validations: [],
  selectors: [],
});

export const FormsProvider: FC<BaseComponent> = ({ children }) => {
  const [{ forms }, formsDispatcher] = useReducer(
    FormState.reducer,
    FormState.defaultState
  );

  const [{ validations }, validationsDispatcher] = useReducer(
    ValidationState.reducer,
    ValidationState.defaultState
  );

  const [{ selectors }, selectorsDispatcher] = useReducer(
    SelectorState.reducer,
    SelectorState.defaultState
  );

  useQuery<Form[]>({
    query: queryFormsApi,
    callback: (forms) =>
      Events.publish(EventTypes.FORMS, createFormInitializeAction(forms)),
  });

  useQuery<Validation[]>({
    query: queryValidationsApi,
    callback: (validations) =>
      Events.publish(
        EventTypes.VALIDATIONS,
        createValidationInitializeAction(validations)
      ),
  });

  useQuery<Selector[]>({
    query: querySelectorsApi,
    callback: (selectors) =>
      Events.publish(
        EventTypes.SELECTORS,
        createSelectorInitializeAction(selectors)
      ),
  });

  useEffect(() => {
    Events.subscribe(EventTypes.FORMS, formsDispatcher);
    Events.subscribe(EventTypes.VALIDATIONS, validationsDispatcher);
    Events.subscribe(EventTypes.SELECTORS, selectorsDispatcher);

    return () => {
      Events.unsubscribe(EventTypes.FORMS, formsDispatcher);
      Events.unsubscribe(EventTypes.VALIDATIONS, validationsDispatcher);
      Events.unsubscribe(EventTypes.SELECTORS, selectorsDispatcher);
    };
  }, []);

  return (
    <FormsContext.Provider value={{ forms, validations, selectors }}>
      {children}
    </FormsContext.Provider>
  );
};
