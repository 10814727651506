import { createFormFieldApi } from '@/api/formFields.api';
import { FieldsFormComponent } from '@/components/FieldsForm';
import { Field } from '@/model/field';
import { createFieldCreatedAction } from '@/store/fields/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const CreateFieldPage: FC = () => {
  let { formId, categoryId } = useParams();

  const successField = (field: Field) => {
    toast('Campo creado.', {
      type: 'success',
    });

    const event = `${field.formId}_${field.categoryId}_${EventTypes.FIELDS}`;
    Events.publish(event, createFieldCreatedAction(field));
  };

  const onSubmit = (field: Field) => {
    return createFormFieldApi({
      ...field,
      formId: +formId!,
      categoryId: +categoryId!,
    }).then((newField) => {
      successField(newField);

      return newField;
    });
  };

  return (
    <section className='container-fluid'>
      <p className='h1 text-primary fw-bold'>Nuevo campo</p>

      <hr className='panel-hr' />

      <FieldsFormComponent onSubmit={onSubmit} />
    </section>
  );
};
