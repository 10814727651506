import { SelectorDetail } from '@/model/selectorDetail';
import { AdminClient } from './AdminClient.config';

const selectorsBaseUrl = '/selectors';
const detailsBaseUrl = 'details';
const selectorDetailsBaseUrl = '/selector-details';

export const createSelectorDetailApi = (detail: SelectorDetail) =>
  AdminClient.post<SelectorDetail>(
    `${selectorsBaseUrl}/${detail.selectorId}/${detailsBaseUrl}`,
    detail
  ).then((response) => response.data);

export const updateSelectorDetailApi = (selector: SelectorDetail) =>
  AdminClient.put<SelectorDetail>(
    `${selectorDetailsBaseUrl}/${selector.id}`,
    selector
  ).then((response) => response.data);

export const querySelectorDetailByIdApi = (detaildId: number) =>
  AdminClient.get<SelectorDetail | null>(
    `${selectorDetailsBaseUrl}/${detaildId}`
  ).then((response) => response.data || null);

export const querySelectorDetailsApi = (selectorId: number) =>
  AdminClient.get<SelectorDetail[]>(
    `${selectorsBaseUrl}/${selectorId}/${detailsBaseUrl}`
  ).then((response) => response.data || []);

export const deleteSelectorDetailApi = (detailId: number) =>
  AdminClient.delete(`${selectorDetailsBaseUrl}/${detailId}`).then(() => {});
