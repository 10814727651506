import { Validation } from '@/model/validation';
import { ChangeEvent, FC, useState } from 'react';

const validationDefaultValue: Validation = {
  name: '',
  message: '',
  validation: '',
};

export interface ValidationsFormComponentProps {
  defaultValue?: Validation;
  onSubmit: (form: Validation) => Promise<Validation>;
  resetOnSubmit?: boolean;
}

export const ValidationsForm: FC<ValidationsFormComponentProps> = ({
  defaultValue,
  onSubmit,
  resetOnSubmit = true,
}) => {
  const [validation, setValidation] = useState(
    defaultValue || validationDefaultValue
  );

  const setInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValidation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetForm = () => setValidation(validationDefaultValue);

  const onFormSubmit = () => {
    onSubmit(validation).then(() => {
      if (resetOnSubmit) {
        resetForm();
      }
    });
  };

  return (
    <form className='d-flex flex-column gap-3'>
      <div>
        <label
          className='form-label'
          htmlFor='name'
          placeholder='Nombre de la validación'
        >
          Nombre
        </label>

        <input
          className='form-control'
          value={validation.name}
          type='text'
          name='name'
          onChange={setInput}
          required
        />
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='message'
          placeholder='Mensaje de la validación'
        >
          Mensaje
        </label>

        <input
          className='form-control'
          value={validation.message}
          type='text'
          name='message'
          onChange={setInput}
          required
        />
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='validation'
          placeholder='Expresión de validación'
        >
          Validación (Expresión Regular)
        </label>

        <input
          className='form-control'
          value={validation.validation}
          type='text'
          name='validation'
          onChange={setInput}
          required
        />
      </div>

      <section className='col-12'>
        <div className='row'>
          <div className='d-grid col-6'>
            <button
              className='btn btn-secondary'
              type='button'
              onClick={resetForm}
            >
              Restablecer
            </button>
          </div>

          <div className='d-grid col-6'>
            <button
              className='btn btn-primary'
              type='button'
              onClick={onFormSubmit}
            >
              Guardar
            </button>
          </div>
        </div>
      </section>
    </form>
  );
};
