import { Category } from '@/model/category';
import { ChangeEvent, FC, useState } from 'react';

const categoryDefaultValue: Category = {
  name: '',
  color: '',
  image: '',
  isVisible: true,
  order: 1,
};

export interface CategoriesFormComponentProps {
  defaultValue?: Category;
  onSubmit: (category: Category) => Promise<Category>;
  resetOnSubmit?: boolean;
}

export const CategoriesFormComponent: FC<CategoriesFormComponentProps> = ({
  defaultValue,
  onSubmit,
  resetOnSubmit = true,
}) => {
  const [category, setCategory] = useState(
    defaultValue || categoryDefaultValue
  );

  const resetForm = () => setCategory(categoryDefaultValue);

  const onFormSubmit = () => {
    onSubmit(category).then(() => {
      if (resetOnSubmit) {
        resetForm();
      }
    });
  };

  const setInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setCategory((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <form className='d-grid gap-3'>
      <div>
        <label
          className='form-label'
          htmlFor='name'
          placeholder='Nombre de la categoría'
        >
          Nombre
        </label>

        <input
          className='form-control'
          value={category.name}
          type='text'
          name='name'
          onChange={setInput}
          required
        />
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='isVisible'
          placeholder='¿Es visible?'
        >
          ¿Es visible?
        </label>

        <select
          className='form-select'
          name='isVisible'
          onChange={setInput as any}
          value={category.isVisible as any}
        >
          <option value={true as any}>Sí</option>

          <option value={false as any}>No</option>
        </select>
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='order'
          placeholder='Orden de la categoría'
        >
          Orden
        </label>

        <input
          className='form-control'
          value={category.order}
          min={1}
          step={1}
          type='number'
          name='order'
          onChange={setInput}
          required
        />
      </div>

      {/* <div>
        <label className='form-label' htmlFor='imagen'>
          Imagen
        </label>

        <input
          className='form-control'
          type='file'
          value={category.image}
          name='image'
          onChange={setInput}
        />
      </div> */}

      <div>
        <label className='form-label' htmlFor='color'>
          Color
        </label>

        <input
          className='form-control'
          value={category.color}
          type='color'
          name='color'
          onChange={setInput}
        />
      </div>

      <section className='col-12'>
        <div className='row'>
          <div className='d-grid col-6'>
            <button
              className='btn btn-secondary'
              type='button'
              onClick={resetForm}
            >
              Restablecer
            </button>
          </div>

          <div className='d-grid col-6'>
            <button
              className='btn btn-primary'
              type='button'
              onClick={onFormSubmit}
            >
              Guardar
            </button>
          </div>
        </div>
      </section>
    </form>
  );
};
