import { ConfirmDialogComponent } from '@/components/ConfirmDialog';
import { CategoryContext, CategoryProvider } from '@/context/Categories';
import { FC, useContext, useState } from 'react';
import { AiFillCaretRight } from 'react-icons/ai';
import { BiMinus, BiPlus, BiSolidPencil } from 'react-icons/bi';
import { FaFolder } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FieldAdapterComponent } from './FieldAdapter';

export const CategoryComponent: FC = () => {
  const {
    category,
    subcategories,
    querySubcategories,
    queryFields,
    fields,
    deleteCategory,
  } = useContext(CategoryContext);

  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    if (!visible) {
      queryFields();
      querySubcategories();
    }

    setVisible((prev) => !prev);
  };

  const onDeleteCategory = () => {
    deleteCategory().then(() => {
      toast('Categoría eliminada.', { type: 'error' });
    });
  };

  return (
    <section>
      <div className='d-flex align-items-center'>
        <span onClick={toggleVisible}>
          <AiFillCaretRight
            className={`caret ${
              visible ? 'caret--active' : ''
            } color--purple-400 text-primary`}
          />
        </span>

        <span className='h4 mb-0 text-primary color--purple-400 d-flex align-items-center gap-2'>
          <FaFolder />
          {category?.name}
        </span>

        <Link
          to={`/plantillas/${category.formId}/categorias/${category.id}`}
          className='ms-2'
        >
          <button
            className='btn btn-dark btn-circle'
            title='Actualizar plantilla'
          >
            <BiSolidPencil size={16} className='crud-btn' />
          </button>
        </Link>

        <Link
          to={`/plantillas/${category.formId}/categorias?parentCategoryId=${category.id}`}
          className='ms-2'
        >
          <button
            className='btn btn-dark btn-circle'
            title='Crear subcategoría'
          >
            <BiPlus size={24} className='crud-btn' />
          </button>
        </Link>

        <Link
          to={`/plantillas/${category.formId}/categorias/${category.id}/campos`}
          className='ms-2'
        >
          <button className='btn btn-primary btn-circle' title='Crear campo'>
            <BiPlus size={24} className='crud-btn' />
          </button>
        </Link>

        <button
          className='btn btn-danger btn-circle ms-2'
          title='Eliminar categoría'
          onClick={() => setIsOpen(true)}
        >
          <BiMinus size={24} className='crud-btn' />
        </button>
      </div>

      <ul className={`ls-none ${visible ? '' : 'visually-hidden'}`}>
        {subcategories.map((category) => (
          <li key={category.id}>
            <CategoryProvider category={category}>
              <CategoryComponent />
            </CategoryProvider>
          </li>
        ))}
      </ul>

      <ul className={`ls-none ${visible ? '' : 'visually-hidden'}`}>
        {fields.map((field) => (
          <li key={field.id}>
            <FieldAdapterComponent field={field} />
          </li>
        ))}
      </ul>

      <ConfirmDialogComponent
        isOpen={isOpen}
        title='Eliminar categoría'
        message='¿Está seguro que desea eliminar esta categoría?'
        onCancel={() => setIsOpen(false)}
        onConfirm={onDeleteCategory}
      />
    </section>
  );
};
