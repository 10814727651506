import { createValidationApi } from '@/api/validation.api';
import { ValidationsForm } from '@/components/ValidationsForm';
import { Validation } from '@/model/validation';
import { createValidationCreatedAction } from '@/store/validations/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const CreateValidationPage: FC = () => {
  const navigate = useNavigate();

  const successField = (validation: Validation) => {
    toast('Validación creada.', {
      type: 'success',
    });

    Events.publish(
      EventTypes.VALIDATIONS,
      createValidationCreatedAction(validation)
    );
    navigate('../');
  };

  const onSubmit = (validation: Validation) => {
    return createValidationApi(validation).then((newValidation) => {
      successField(newValidation);

      return newValidation;
    });
  };

  return (
    <section className='container-fluid'>
      <p className='h1 text-primary fw-bold'>Nueva validación</p>

      <hr className='panel-hr' />

      <ValidationsForm onSubmit={onSubmit} />
    </section>
  );
};
