import { FC } from 'react';
import { Link } from 'react-router-dom';

export const NavBar: FC = () => {
  return (
    <nav
      className='w-100 d-flex px-3 gap-5 align-items-center bg-white'
      style={{ height: '80px' }}
    >
      <Link to='/validaciones' className='fs-3 fw-bold text-decoration-none'>
        Validaciones
      </Link>

      <Link to='/selectores' className='fs-3 fw-bold text-decoration-none'>
        Selectores
      </Link>
    </nav>
  );
};
