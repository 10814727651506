import { Field } from '@/model/field';
import { AdminClient } from './AdminClient.config';

const categoriesBaseUrl = '/categories';
const fieldsBaseUrl = 'fields';

export const queryCategoryFieldsApi = (categoryId: number) =>
  AdminClient.get<Field[]>(
    `${categoriesBaseUrl}/${categoryId}/${fieldsBaseUrl}`
  ).then((response) => response.data || []);
