import { createTableFieldApi } from '@/api/tableFields.api';
import { TableFieldsFormComponent } from '@/components/TableFieldsForm';
import { TableField } from '@/model/tableField';
import { createTableFieldCreatedAction } from '@/store/tableFields/actions';

import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const CreateTableFieldPage: FC = () => {
  let { fieldId } = useParams();

  const successTableField = (tableField: TableField) => {
    toast('Campo creado.', {
      type: 'success',
    });

    const event = `${tableField.fieldId}_${EventTypes.TABLE_FIELDS}`;
    Events.publish(event, createTableFieldCreatedAction(tableField));
  };

  const onSubmit = (tableField: TableField) => {
    return createTableFieldApi({
      ...tableField,
      fieldId: +fieldId!,
    }).then((newTableField) => {
      successTableField(newTableField);

      return newTableField;
    });
  };

  return (
    <section className='container-fluid'>
      <p className='h1 text-primary fw-bold'>Nuevo campo</p>

      <hr className='panel-hr' />

      <TableFieldsFormComponent onSubmit={onSubmit} />
    </section>
  );
};
