import { Form } from '@/model/form';

export interface IFormState {
  forms: Form[];
}

export enum FormStateActionType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  INITIALIZE = 'INITIALIZE',
}

export interface FormStateActionBase {
  type: FormStateActionType;
}

export interface FormInitializeAction extends FormStateActionBase {
  type: FormStateActionType.INITIALIZE;
  payload: Form[];
}

export interface FormCreatedAction extends FormStateActionBase {
  type: FormStateActionType.CREATED;
  payload: Form;
}

export interface FormUpdatedAction extends FormStateActionBase {
  type: FormStateActionType.UPDATED;
  payload: Form;
}

export interface FormDeletedAction extends FormStateActionBase {
  type: FormStateActionType.DELETED;
  payload: number;
}

export type FormStateAction =
  | FormInitializeAction
  | FormCreatedAction
  | FormUpdatedAction
  | FormDeletedAction;
