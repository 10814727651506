import { Category } from '@/model/category';
import { AdminClient } from './AdminClient.config';

const categoryBaseUrl = '/categories';

export const queryCategoryByIdApi = (categoryId: number) =>
  AdminClient.get<Category | null>(`${categoryBaseUrl}/${categoryId}`).then(
    (response) => response.data || null
  );

export const updateCategoryApi = (category: Category) =>
  AdminClient.put<Category>(`${categoryBaseUrl}/${category.id}`, category).then(
    (response) => response.data
  );

export const deleteCategoryApi = (categoryId: number) =>
  AdminClient.delete(`${categoryBaseUrl}/${categoryId}`).then(() => {});
