import { Enablement, EnablementRequest } from '@/model/enablement';
import { AdminClient } from './AdminClient.config';

const fieldsBaseUrl = '/table-fields';
const enablementBaseUrl = 'enablement';

export const queryTableFieldEnablementApi = (tableFieldId: number) =>
  AdminClient.get<Enablement>(
    `${fieldsBaseUrl}/${tableFieldId}/${enablementBaseUrl}`
  ).then((response) => response.data || null);

export const createTableFieldEnablementApi = (
  tableFieldId: number,
  payload: EnablementRequest
) =>
  AdminClient.post<void>(
    `${fieldsBaseUrl}/${tableFieldId}/${enablementBaseUrl}`,
    payload
  ).then(() => {});

export const deleteTableFieldEnablementApi = (
  tableField: number,
  payload: EnablementRequest
) =>
  AdminClient.put<void>(
    `${fieldsBaseUrl}/${tableField}/${enablementBaseUrl}`,
    payload
  ).then(() => {});
