import { Dispatch } from 'react';
import {
  formCreatedAction,
  formDeletedAction,
  formInitializeAction,
  formUpdatedAction,
} from './actions';
import { FormStateAction, FormStateActionType, IFormState } from './model';

const defaultState: IFormState = { forms: [] };

const reducer = (
  state: IFormState = defaultState,
  { detail: action }: CustomEvent<FormStateAction>
) => {
  switch (action.type) {
    case FormStateActionType.INITIALIZE:
      return formInitializeAction(state, action);
    case FormStateActionType.CREATED:
      return formCreatedAction(state, action);
    case FormStateActionType.UPDATED:
      return formUpdatedAction(state, action);
    case FormStateActionType.DELETED:
      return formDeletedAction(state, action);
    default:
      return { ...state };
  }
};

const eventsHanlder = (
  event: CustomEvent<FormStateAction>,
  dispatcher: Dispatch<FormStateAction>
) => dispatcher(event.detail);

const FormState = Object.freeze({ reducer, defaultState, eventsHanlder });

export default FormState;
