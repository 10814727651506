import { queryTableFieldsApi } from '@/api/tableFields.api';
import { BaseComponent } from '@/model/BaseComponent';
import { Field } from '@/model/field';
import { TableField } from '@/model/tableField';
import TableFieldState from '@/store/tableFields';
import { createTableFieldInitializeAction } from '@/store/tableFields/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { noOpFn } from '@/util/Functions.utils';
import {
  FC,
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';

export interface TableFieldsContextType {
  field: Field;
  tableFields: TableField[];
  queryTableFields: () => void;
}

export const TableFieldsContext = createContext<TableFieldsContextType>({
  field: {} as any,
  tableFields: [],
  queryTableFields: noOpFn,
});

export interface TableFieldProviderProps extends BaseComponent {
  field: Field;
}

export const TableFieldProvider: FC<TableFieldProviderProps> = ({
  field,
  children,
}) => {
  const [{ tableFields }, tableFieldsDispatcher] = useReducer(
    TableFieldState.reducer,
    TableFieldState.defaultState
  );

  const [loadTableFields, setLoadTableFields] = useState(true);

  const queryTableFields = useCallback(() => {
    if (!loadTableFields) return;

    setLoadTableFields(false);

    queryTableFieldsApi({ fieldId: field.id! })
      .then((foundFields) => {
        Events.publish(
          `${field.id}_${EventTypes.TABLE_FIELDS}`,
          createTableFieldInitializeAction(foundFields || [])
        );
        setLoadTableFields(false);
      })
      .catch(() => {
        setLoadTableFields(true);
      });
  }, [field, loadTableFields]);

  useEffect(() => {
    Events.subscribe(
      `${field.id}_${EventTypes.TABLE_FIELDS}`,
      tableFieldsDispatcher
    );

    return () => {
      Events.unsubscribe(
        `${field.id}_${EventTypes.TABLE_FIELDS}`,
        tableFieldsDispatcher
      );
    };
  }, [field]);

  return (
    <TableFieldsContext.Provider
      value={{ field, tableFields, queryTableFields }}
    >
      {children}
    </TableFieldsContext.Provider>
  );
};
