import {
  queryTableFieldByIdApi,
  updateTableFieldApi,
} from '@/api/tableFields.api';
import { TableFieldsFormComponent } from '@/components/TableFieldsForm';
import { TableField } from '@/model/tableField';
import { createTableFieldUpdatedAction } from '@/store/tableFields/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const UpdateTableFieldPage: FC = () => {
  const { tableFieldId } = useParams<{ tableFieldId: string }>();
  const [tableField, setTableField] = useState<TableField | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let dropResult = false;
    setIsLoading(true);

    queryTableFieldByIdApi(+tableFieldId!)
      .then((foundTableField) => {
        if (dropResult) {
          return;
        }

        setTableField(foundTableField);
      })
      .finally(() => {
        if (dropResult) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      dropResult = true;
    };
  }, [tableFieldId]);

  const onSuccess = (tableField: TableField) => {
    toast('Campo Actualizado.', {
      type: 'info',
    });

    Events.publish(
      `${tableField.fieldId}_${EventTypes.TABLE_FIELDS}`,
      createTableFieldUpdatedAction(tableField)
    );
  };

  const onSubmit = (tableField: TableField) => {
    return updateTableFieldApi({ ...tableField, id: +tableFieldId! }).then(
      (updatedTableField) => {
        onSuccess(updatedTableField);
        return updatedTableField;
      }
    );
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <section className='container-fluid'>
      <p className='h1 text-primary fw-bold'>Actualizar campo</p>
      <hr className='panel-hr' />

      <TableFieldsFormComponent
        defaultValue={tableField!}
        onSubmit={onSubmit}
        resetOnSubmit={false}
      />
    </section>
  );
};
