import { Dispatch } from 'react';
import {
  tableFieldCreatedAction,
  tableFieldDeletedAction,
  tableFieldInitializeAction,
  tableFieldUpdatedAction,
} from './actions';
import {
  ITableFieldState,
  TableFieldStateAction,
  TableFieldStateActionType,
} from './model';

const defaultState: ITableFieldState = { tableFields: [] };

const reducer = (
  state: ITableFieldState = defaultState,
  { detail: action }: CustomEvent<TableFieldStateAction>
) => {
  switch (action.type) {
    case TableFieldStateActionType.INITIALIZE:
      return tableFieldInitializeAction(state, action);
    case TableFieldStateActionType.CREATED:
      return tableFieldCreatedAction(state, action);
    case TableFieldStateActionType.UPDATED:
      return tableFieldUpdatedAction(state, action);
    case TableFieldStateActionType.DELETED:
      return tableFieldDeletedAction(state, action);
    default:
      return { ...state };
  }
};

const eventsHanlder = (
  event: CustomEvent<TableFieldStateAction>,
  dispatcher: Dispatch<TableFieldStateAction>
) => dispatcher(event.detail);

const TableFieldState = Object.freeze({ reducer, defaultState, eventsHanlder });

export default TableFieldState;
