import { TableField } from '@/model/tableField';
import {
  ITableFieldState,
  TableFieldCreatedAction,
  TableFieldDeletedAction,
  TableFieldInitializeAction,
  TableFieldStateActionType,
  TableFieldUpdatedAction,
} from './model';

export const createTableFieldInitializeAction = (
  tableFields: TableField[]
): TableFieldInitializeAction => ({
  type: TableFieldStateActionType.INITIALIZE,
  payload: tableFields,
});

export const tableFieldInitializeAction = (
  state: ITableFieldState,
  action: TableFieldInitializeAction
): ITableFieldState => ({
  ...state,
  tableFields: action.payload.slice(),
});

export const createTableFieldCreatedAction = (
  tableField: TableField
): TableFieldCreatedAction => ({
  type: TableFieldStateActionType.CREATED,
  payload: tableField,
});

export const tableFieldCreatedAction = (
  state: ITableFieldState,
  action: TableFieldCreatedAction
): ITableFieldState => ({
  ...state,
  tableFields: state.tableFields.concat(action.payload),
});

export const createTableFieldUpdatedAction = (
  tableField: TableField
): TableFieldUpdatedAction => ({
  type: TableFieldStateActionType.UPDATED,
  payload: tableField,
});

export const tableFieldUpdatedAction = (
  state: ITableFieldState,
  { payload }: TableFieldUpdatedAction
): ITableFieldState => ({
  ...state,
  tableFields: state.tableFields.map((tableField) =>
    tableField.id === payload.id ? payload : tableField
  ),
});

export const createTableFieldDeletedAction = (
  id: number
): TableFieldDeletedAction => ({
  type: TableFieldStateActionType.DELETED,
  payload: id,
});

export const tableFieldDeletedAction = (
  state: ITableFieldState,
  action: TableFieldDeletedAction
): ITableFieldState => ({
  ...state,
  tableFields: state.tableFields.filter(
    (tableField) => tableField.id !== action.payload
  ),
});
