import { SchemaComponent } from '@/components/Schema';
import { BaseComponent } from '@/model/BaseComponent';
import { FC } from 'react';

export const MainPage: FC<BaseComponent> = ({ children }) => {
  return (
    <main className='container-fluid py-3 d-grid align-items-stretch'>
      <section className='row gx-3'>
        <article className='col-6'>
          <div className='card min-h-100'>
            <div className='card-body'>
              <SchemaComponent />
            </div>
          </div>
        </article>

        <article className='col-6'>
          <div className='card min-h-100'>
            <div className='card-body'>{children}</div>
          </div>
        </article>
      </section>
    </main>
  );
};
