import { FormsContext } from '@/context/Forms';
import { Field } from '@/model/field';
import { FieldType } from '@/model/fieldType';
import { ChangeEvent, FC, useContext, useState } from 'react';

const fieldDefaultValue: Field = {
  description: '',
  formId: 0,
  isDisabled: false,
  isRequiered: false,
  isVisible: true,
  keyboardType: 0,
  name: '',
  order: 1,
  type: 'string',
};

export interface FieldsFormComponentProps {
  defaultValue?: Field;
  onSubmit: (field: Field) => Promise<Field>;
  resetOnSubmit?: boolean;
}

export const FieldsFormComponent: FC<FieldsFormComponentProps> = ({
  defaultValue,
  onSubmit,
  resetOnSubmit = true,
}) => {
  const [field, setField] = useState<Field>(defaultValue || fieldDefaultValue);
  const { validations, selectors } = useContext(FormsContext);

  const setInput = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetForm = () => setField(fieldDefaultValue);

  const onFormSubmit = () => {
    onSubmit(field).then(() => {
      if (resetOnSubmit) {
        resetForm();
      }
    });
  };

  return (
    <form className='d-grid gap-3'>
      <div>
        <label
          className='form-label'
          htmlFor='name'
          placeholder='Nombre del campo'
        >
          Nombre
        </label>

        <input
          className='form-control'
          value={field.name}
          type='text'
          name='name'
          onChange={setInput}
          required
        />
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='description'
          placeholder='Descripción del campo'
        >
          Descripción
        </label>

        <input
          className='form-control'
          value={field.description}
          type='text'
          name='description'
          onChange={setInput}
          required
        />
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='maximumValue'
          placeholder='Valor máximo del campo'
        >
          Valor máximo
        </label>

        <input
          className='form-control'
          value={field.maximumValue}
          step={1}
          type='number'
          name='maximumValue'
          onChange={setInput}
          required
        />
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='minimumValue'
          placeholder='Valor mínimo del campo'
        >
          Valor mínimo
        </label>

        <input
          className='form-control'
          value={field.minimumValue}
          step={1}
          type='number'
          name='minimumValue'
          onChange={setInput}
          required
        />
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='defaultValue'
          placeholder='Valor por defecto del campo'
        >
          Valor por defecto
        </label>

        <input
          className='form-control'
          value={field.defaultValue as any}
          type='text'
          name='defaultValue'
          onChange={setInput}
          required
        />
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='ranges'
          placeholder='Rangos del campo'
        >
          Rangos
        </label>

        <input
          className='form-control'
          value={field.ranges}
          type='text'
          name='ranges'
          onChange={setInput}
          required
        />
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='order'
          placeholder='Orden del campo'
        >
          Orden
        </label>

        <input
          className='form-control'
          value={field.order}
          min={1}
          step={1}
          type='number'
          name='order'
          onChange={setInput}
          required
        />
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='isVisible'
          placeholder='¿Es visible?'
        >
          ¿Es visible?
        </label>

        <select
          className='form-select'
          name='isVisible'
          onChange={setInput}
          value={field.isVisible as any}
        >
          <option value={true as any}>Sí</option>

          <option value={false as any}>No</option>
        </select>
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='type'
          placeholder='Tipo de campo'
        >
          Tipo
        </label>

        <select
          className='form-select'
          name='type'
          onChange={setInput}
          value={field.type}
        >
          <option value={undefined}>Seleccionar</option>

          {Object.values(FieldType).map((fieldType) => (
            <option key={fieldType} value={fieldType}>
              {fieldType}
            </option>
          ))}
        </select>
      </div>

      {field.type === FieldType.SELECT && (
        <div>
          <label
            className='form-label'
            htmlFor='selectorId'
            placeholder='Selector del campo'
          >
            Selector
          </label>

          <select
            className='form-select'
            name='selectorId'
            onChange={setInput}
            value={field.selectorId}
          >
            <option value={undefined}>Seleccionar</option>

            {selectors.map((selector) => (
              <option key={selector.id} value={selector.id}>
                {selector.name}
              </option>
            ))}
          </select>
        </div>
      )}

      <div>
        <label
          className='form-label'
          htmlFor='isRequiered'
          placeholder='¿Es requerido?'
        >
          ¿Es requerido?
        </label>

        <select
          className='form-select'
          name='isRequiered'
          onChange={setInput}
          value={field.isRequiered as any}
        >
          <option value={true as any}>Sí</option>

          <option value={false as any}>No</option>
        </select>
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='isDisabled'
          placeholder='¿Deshabilitar inicialmente?'
        >
          ¿Deshabilitar inicialmente?
        </label>

        <select
          className='form-select'
          name='isDisabled'
          onChange={setInput}
          value={field.isDisabled as any}
        >
          <option value={true as any}>Sí</option>

          <option value={false as any}>No</option>
        </select>
      </div>

      <div>
        <label
          className='form-label'
          htmlFor='validationId'
          placeholder='Validación del campo'
        >
          Validación
        </label>

        <select
          className='form-select'
          name='validationId'
          onChange={setInput}
          value={field.validationId}
        >
          <option value={undefined}>Seleccionar</option>

          {validations.map((validation) => (
            <option key={validation.id} value={validation.id}>
              {validation.name}
            </option>
          ))}
        </select>
      </div>

      <section className='col-12'>
        <div className='row'>
          <div className='d-grid col-6'>
            <button
              className='btn btn-secondary'
              type='button'
              onClick={resetForm}
            >
              Restablecer
            </button>
          </div>

          <div className='d-grid col-6'>
            <button
              className='btn btn-primary'
              type='button'
              onClick={onFormSubmit}
            >
              Guardar
            </button>
          </div>
        </div>
      </section>
    </form>
  );
};
