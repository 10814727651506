import { deleteTableFieldApi } from '@/api/tableFields.api';
import { ConfirmDialogComponent } from '@/components/ConfirmDialog';
import { FormsContext } from '@/context/Forms';
import { FieldType } from '@/model/fieldType';
import { TableField } from '@/model/tableField';
import { createTableFieldDeletedAction } from '@/store/tableFields/actions';
import EventTypes from '@/util/EventTypes.constants';
import Events from '@/util/Events';
import { FC, useContext, useState } from 'react';
import { AiFillCaretRight } from 'react-icons/ai';
import { BiMinus, BiSolidPencil } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FieldIconAdapterComponent } from './FieldIconAdapter';
import { FieldTableEnablementComponent } from './TableEnablement';

export interface TableFieldComponentProps {
  tableField: TableField;
}

export const TableFieldComponent: FC<TableFieldComponentProps> = ({
  tableField,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const { validations, selectors } = useContext(FormsContext);

  const toggleVisible = () => {
    setVisible((prev) => !prev);
  };

  const onDeleteTableField = () => {
    deleteTableFieldApi(tableField?.id!).then(() => {
      toast('Campo eliminado.', { type: 'error' });
      Events.publish(
        `${tableField?.fieldId}_${EventTypes.TABLE_FIELDS}`,
        createTableFieldDeletedAction(tableField?.id!)
      );
    });
  };

  if (!tableField) {
    return null;
  }

  return (
    <div>
      <div className='d-flex align-items-center'>
        <span onClick={toggleVisible} className='d-flex align-items-center'>
          <AiFillCaretRight
            className={`caret ${visible ? 'caret--active' : ''} text-primary`}
          />
        </span>

        <span className='h4 mb-0 text-primary d-flex align-items-center gap-2'>
          <FieldIconAdapterComponent fieldType={tableField.type} />
          {tableField.name}
        </span>

        <Link
          to={`/tablas/${tableField.fieldId}/campos/${tableField.id}`}
          className='ms-2'
        >
          <button className='btn btn-dark btn-circle' title='Actualizar campo'>
            <BiSolidPencil size={16} className='crud-btn' />
          </button>
        </Link>

        <button
          className='btn btn-danger btn-circle ms-2'
          title='Eliminar campo'
          onClick={() => setIsOpen(true)}
        >
          <BiMinus size={24} className='crud-btn' />
        </button>
      </div>

      <ul className={`ls-none ${visible ? '' : 'visually-hidden'}`}>
        {tableField.validationId && (
          <li className='h5'>
            Validación:{' '}
            {
              validations.find(
                (validation) => validation.id === tableField.validationId
              )?.name
            }
          </li>
        )}

        {tableField.selectorId && (
          <li className='h5'>
            Selector:{' '}
            {
              selectors.find(
                (selector) => selector.id === tableField.selectorId
              )?.name
            }
          </li>
        )}

        {tableField.type === FieldType.BOOLEAN && (
          <FieldTableEnablementComponent field={tableField} />
        )}
      </ul>

      <ConfirmDialogComponent
        isOpen={isOpen}
        title='Eliminar campo'
        message='¿Está seguro que desea eliminar este campo?'
        onCancel={() => setIsOpen(false)}
        onConfirm={onDeleteTableField}
      />
    </div>
  );
};
