import { useEffect, useState } from 'react';

export interface UseQueryParams<R> {
  query: () => Promise<R>;
  callback: (result: R) => void;
}

export const useQuery = <R,>({ query, callback }: UseQueryParams<R>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading && !isLoaded) {
      setIsLoading(true);

      query()
        .then(callback)
        .finally(() => {
          setIsLoaded(true);
          setIsLoading(false);
        });
    }
  }, [isLoading, isLoaded, query, callback]);

  return { isLoading, isLoaded };
};
