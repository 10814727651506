import { Category, CategoryFilter } from '@/model/category';
import { AdminClient } from './AdminClient.config';

const formsBaseUrl = '/forms';
const categoriesBaseUrl = 'categories/';

export const createFormCategoryApi = (category: Category) =>
  AdminClient.post<Category>(
    `${formsBaseUrl}/${category.formId}/${categoriesBaseUrl}`,
    category
  ).then((response) => response.data);

export const queryFormCategoriesApi = (filter: CategoryFilter) => {
  const { formId, ...rest } = filter;

  return AdminClient.get<Category[]>(
    `${formsBaseUrl}/${filter.formId}/${categoriesBaseUrl}`,
    { params: rest }
  ).then((response) => response.data || []);
};
