import { Category } from '@/model/category';

export interface ICategoryState {
  categories: Category[];
}

export enum CategoryStateActionType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  INITIALIZE = 'INITIALIZE',
}

export interface CategoryStateActionBase {
  type: CategoryStateActionType;
}

export interface CategoryInitializeAction extends CategoryStateActionBase {
  type: CategoryStateActionType.INITIALIZE;
  payload: Category[];
}

export interface CategoryCreatedAction extends CategoryStateActionBase {
  type: CategoryStateActionType.CREATED;
  payload: Category;
}

export interface CategoryUpdatedAction extends CategoryStateActionBase {
  type: CategoryStateActionType.UPDATED;
  payload: Category;
}

export interface CategoryDeletedAction extends CategoryStateActionBase {
  type: CategoryStateActionType.DELETED;
  payload: number;
}

export type CategoryStateAction =
  | CategoryInitializeAction
  | CategoryCreatedAction
  | CategoryUpdatedAction
  | CategoryDeletedAction;
